<template>
  <div class="bg-black flex-1 space-y-10">
    <brand-panel-app-screen-shot></brand-panel-app-screen-shot>
    <simple-centered-branded></simple-centered-branded>
    <simple-centered></simple-centered>
    <simple-justified-on-light-brand></simple-justified-on-light-brand>
    <split-with-image></split-with-image>
    <brand-panel-with-overlapping-image></brand-panel-with-overlapping-image>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import BrandPanelAppScreenShot from "./../../../components/tailwind-base/marketing/cta/BrandPanelAppScreenShot.vue";
import SimpleCenteredBranded from "./../../../components/tailwind-base/marketing/cta/SimpleCenteredBranded.vue";
import SimpleCentered from "./../../../components/tailwind-base/marketing/cta/SimpleCentered.vue";
import SimpleJustifiedOnLightBrand from "./../../../components/tailwind-base/marketing/cta/SimpleJustifiedOnLightBrand.vue";
import SplitWithImage from "./../../../components/tailwind-base/marketing/cta/SplitWithImage.vue";
import BrandPanelWithOverlappingImage from "./../../../components/tailwind-base/marketing/cta/BrandPanelWithOverlappingImage.vue";

export default {
  components: {
    BrandPanelAppScreenShot,
    SimpleCenteredBranded,
    SimpleCentered,
    SimpleJustifiedOnLightBrand,
    SplitWithImage,
    BrandPanelWithOverlappingImage
  },
  setup() {
    const state = reactive({
      count: 0
    });

    return {
      ...toRefs(state)
    };
  }
};
</script>

<style lang="scss" scoped></style>
