<template>
  <div class="bg-black flex-1 space-y-10">
    <simple-centered></simple-centered>
    <split-with-navbar></split-with-navbar>
    <angled-image></angled-image>
    <with-screen-shot></with-screen-shot>
    <with-sign-in></with-sign-in>
    <with-sign-up-and-media></with-sign-up-and-media>
    <card-with-background-image></card-with-background-image>
    <dark-with-illustration></dark-with-illustration>
    <split-with-screenshot></split-with-screenshot>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import SimpleCentered from "./../../../components/tailwind-base/marketing/hero/SimpleCentered.vue";
import SplitWithNavbar from "./../../../components/tailwind-base/marketing/hero/SplitWithNavbar.vue";
import AngledImage from "./../../../components/tailwind-base/marketing/hero/AngledImage.vue";
import WithScreenShot from "./../../../components/tailwind-base/marketing/hero/WithScreenShot.vue";
import WithSignIn from "./../../../components/tailwind-base/marketing/hero/WithSignIn.vue";
import WithSignUpAndMedia from "./../../../components/tailwind-base/marketing/hero/WithSignUpAndMedia.vue";
import CardWithBackgroundImage from "./../../../components/tailwind-base/marketing/hero/CardWithBackgroundImage.vue";
import DarkWithIllustration from "./../../../components/tailwind-base/marketing/hero/DarkWithIllustration.vue";
import SplitWithScreenshot from "./../../../components/tailwind-base/marketing/hero/SplitWithScreenshot.vue";

export default {
  components: {
    SimpleCentered,
    SplitWithNavbar,
    AngledImage,
    WithScreenShot,
    WithSignIn,
    WithSignUpAndMedia,
    CardWithBackgroundImage,
    DarkWithIllustration,
    SplitWithScreenshot
  },
  setup() {
    const state = reactive({
      count: 0
    });

    return {
      ...toRefs(state)
    };
  }
};
</script>

<style lang="scss" scoped></style>
