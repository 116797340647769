<template>
  <div>
    <h2>Turndown Services</h2>
    <ul>
      <li v-for="service in turndownServices" :key="service.guid">
        {{ service }}
      </li>
    </ul>
    <div> 
      {{ invoicesAndTurndownTotals }}
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  setup() {
    const store = useStore();
    const turndownServices = computed(
      () => store.state.turndownServices.results || []
    );
    const invoicesAndTurndownTotals = computed(() => {
      let invoicesCount = store.state.fleetSummary.invoices.length;
      return invoicesCount;
    });

    return {
      turndownServices: turndownServices.value,
      invoicesAndTurndownTotals: invoicesAndTurndownTotals.value
    };
  }
};
</script>
