<template>
  <Suspense>
    <template #default>
      <main class="flex flex-col min-h-screen max-h-screen montserrat">
        <header
          class="flex bg-primary text-white shadow montserrat"
          v-if="$route.meta.title"
        >
          <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 text-left">
            <h1 class="text-3xl leading-tight">[LOGO]</h1>
          </div>
          <div class="w-full"></div>
          <div class="h-auto flex items-center pr-2 mr-2">
            <div class="">{{ accountLink }}</div>
          </div>
        </header>
        <div v-if="$route.meta.title" class=" p-4 m-4 ">
          <div class='text-4xl'> {{$route.meta.title}} </div>
          <div class='w-full '> 
            <router-view />
          </div>
        </div>
        <div class='p-4' v-else>
          <router-view />
        </div>
        <div v-if="$route.meta.title" class="bg-gray-100">
          <div class="w-full">
            <!-- <section id="bottom-navigation" class="md:hidden block fixed inset-x-0 bottom-0 z-10 bg-white shadow"> // if shown only tablet/mobile-->
            <section
              id="bottom-navigation"
              class=" inset-x-0 bottom-0 z-10 shadow rounded-b"
            >
              <div id="tabs" class="flex justify-between">
                <!-- <a
              href=""
              class="w-full text-primary justify-center inline-block text-center pt-2 pb-1"
            >
              <i class="fas fa-file-medical"></i>
              <span class="tab tab-kategori block text-xs">History</span>
            </a>
            <a
              href=""
              class="w-full text-primary justify-center inline-block text-center pt-2 pb-1"
            >
              <i class="far fa-user-circle"></i>
              <span class="tab tab-kategori block text-xs">Profile</span>
            </a>
            <a
              href=""
              class="w-full text-primary justify-center inline-block text-center pt-2 pb-1"
            >
              <i class="fas fa-sign-out-alt text-primary"></i>
              <span class="tab tab-whishlist block text-xs">Logout</span>
            </a> -->
              </div>
            </section>
          </div>
        </div>
      </main>
    </template>
    <template #loading>
      loading....
    </template>
  </Suspense>
</template>

<script>
export default {
  mounted() {},
 
  computed: {
    accountLink() {
      if (!this.$store.state.isLoggedIn) {
        return "Login";
      }
      return "Logout";
    }
  }
};
</script>
<style></style>
