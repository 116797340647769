<template>
  <div class="container mx-auto">
    <Login />
  </div>
</template>

<script>
import Login from "@/components/login/Login.vue";

export default {
  components: {
    Login
  }
};
</script>
