<template>
  <div>
    Search
    <div v-for="result in searchResults" :key="result[0]">
      {{ result[0] }}
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import { mapState } from "vuex";
export default {
  async setup() {
    console.log("loaded");
    const state = reactive({
      count: 0
    });

    return {
      ...toRefs(state)
    };
  },
  computed: {
    ...mapState["searchResults"]
  }
};
</script>

<style lang="scss" scoped></style>
