<template>
  <div class="overflow-y-scroll w-full p-2">
    <div class="flex min-w-full p-2 m-2">
      <div class="flex-grow text-2xl">Invoices</div>
      <div class="flex-grow text-right">
        <!-- <a href="/apps/admin/invoices" class="text-indigo-600 m-1 p-1 text-sm"
          >View All <i class="fa fa-share"></i
        ></a> -->
      </div>
    </div>
    <table class="w-full overflow-y-scroll divide-y divide-gray-200">
      <thead class="bg-gray-50">
        <tr>
          <th
            scope="col"
            class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Invoice ID
          </th>
          <th
            scope="col"
            class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Service Date
          </th>
          <th
            scope="col"
            class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Store #
          </th>
          <th
            scope="col"
            class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Invoice Total
          </th>
          <th
            scope="col"
            class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Balance
          </th>
          <!-- <th
            scope="col"
            class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            View
          </th> -->
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(invoice, index) in invoices"
          :key="invoice.invoiceId"
          :class="index % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
        >
          <td
            class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
          >
            {{ invoice.invoiceId }}
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {{ invoice.serviceDate }}
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {{ invoice.storeNumber }}
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {{ numberAsCurrency(invoice.invoiceTotal) }}
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {{ numberAsCurrency(invoice.balance) }}
          </td>
          <!-- <td
            class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
          >
            <a
              :href="`/apps/admin/invoices/${invoice.invoiceId}`"
              class="text-indigo-600 hover:text-indigo-900"
            >
              <i class="text-blue-500 fa fa-eye"></i>
            </a>
          </td> -->
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
//import { reactive, ref, toRefs } from "vue";
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  setup() {
    const store = useStore();
    const invoices = computed(() => store.state.fleetSummary.invoices || []);

    const numberAsCurrency = (number) => {
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD"
      });
      return formatter.format(number);
    }

    return {
      //invoices,
      invoices,
      numberAsCurrency
      // ...toRefs(state)
    };
  }
};
</script>

<style lang="scss" scoped></style>
