<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="bg-white">
    <div class="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
      <div class="max-w-3xl mx-auto text-center">
        <h2 class="text-3xl font-extrabold text-gray-900">
          All-in-one platform
        </h2>
        <p class="mt-4 text-lg text-gray-500">
          Ac euismod vel sit maecenas id pellentesque eu sed consectetur.
          Malesuada adipiscing sagittis vel nulla nec.
        </p>
      </div>
      <dl
        class="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8"
      >
        <div v-for="feature in features" :key="feature.name" class="relative">
          <dt>
            <CheckIcon
              class="absolute h-6 w-6 text-green-500"
              aria-hidden="true"
            />
            <p class="ml-9 text-lg leading-6 font-medium text-gray-900">
              {{ feature.name }}
            </p>
          </dt>
          <dd class="mt-2 ml-9 text-base text-gray-500">
            {{ feature.description }}
          </dd>
        </div>
      </dl>
    </div>
  </div>
</template>

<script>
import { CheckIcon } from "@heroicons/vue/outline";

const features = [
  {
    name: "Invite team members",
    description:
      "Tempor tellus in aliquet eu et sit nulla tellus. Suspendisse est, molestie blandit quis ac. Lacus."
  },
  {
    name: "Notifications",
    description:
      "Ornare donec rhoncus vitae nisl velit, neque, mauris dictum duis. Nibh urna non parturient."
  },
  {
    name: "List view",
    description:
      "Etiam cras augue ornare pretium sit malesuada morbi orci, venenatis. Dictum lacus."
  },
  {
    name: "Boards",
    description:
      "Interdum quam pulvinar turpis tortor, egestas quis diam amet, natoque. Mauris sagittis."
  },
  {
    name: "Keyboard shortcuts",
    description:
      "Ullamcorper in ipsum ac feugiat. Senectus at aliquam vulputate mollis nec. In at risus odio."
  },
  {
    name: "Reporting",
    description:
      "Magna a vel sagittis aliquam eu amet. Et lorem auctor quam nunc odio. Sed bibendum."
  },
  {
    name: "Calendars",
    description:
      "Sed mi, dapibus turpis orci posuere integer. A porta viverra posuere adipiscing turpis."
  },
  {
    name: "Mobile app",
    description:
      "Quisque sapien nunc nisl eros. Facilisis sagittis maecenas id dignissim tristique proin sed."
  }
];

export default {
  components: {
    CheckIcon
  },
  setup() {
    return {
      features
    };
  }
};
</script>
