<template>
  <div class="items-center justify-center">
    <div class="font-medium text-center text-2xl">
      Amount Due By {{ dueDate }}
    </div>
    <div class="font-light text-center text-4xl">{{ totalAsNumber }}</div>
    <div class="font-normal text-center text-black">Includes Past Due</div>
  </div>
  <div class="py-4 w-full text-center pt-6">
    <div
      class="rounded-full h-60 w-60 border-2 bg-green-400 flex items-center mx-auto hover-indigo-600"
    >
      <div class="mx-auto">
        <a
          class="text-4xl text-white font-semibold"
          :href="
            `https://myfleetcenter.com/myfleetcenter/shared/invoicelookup.aspx?sid=${this.sessionId}&lang=en-US`
          "
          :disabled="totalAsNumber === '$0.00'"
        >
          Pay Now
        </a>
      </div>
    </div>
  </div>
  <div v-if="showCredit" class="flex-1 items-center justify-center">
    <div class="font-light text-center uppercase">
      You have a credit of
    </div>
    <div class="font-light text-center uppercase">
      <span class="text-4xl"> {{ credit }} </span>
    </div>
    <div class="font-medium text-center uppercase">
      Would you like to use it?
    </div>
    <div class="font-normal text-center text-black p-2 m-2">
      <a
        :href="
          `https://myfleetcenter.com/myfleetcenter/shared/invoicelookup.aspx?sid=${this.sessionId}&lang=en-US`
        "
        class="bg-green-500 text-white rounded shadow p-2 m-2"
      >
        Click Here To Apply Credit
      </a>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
export default {
  setup() {
    const store = useStore();
    const sessionId = store.state.sessionId;
    const totalAsNumber = computed(() => {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD"
      });
      return formatter.format(store.state.fleetSummary.balance);
    });

    const credit = computed(() => {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD"
      });
      return formatter.format(store.state.fleetSummary.credit);
    });
    console.log(credit.value, typeof credit.value);
    const showCredit = computed(() => {
      return credit.value !== "$0.00";
    });
    const dueDate = computed(() => {
      const today = new Date();
      const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      return lastDay.toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
      });
    });

    return {
      totalAsNumber,
      credit,
      dueDate,
      sessionId,
      showCredit
    };
  },
  methods: {
    buttonClicked() {
      window.open(
        `https://myfleetcenter.com/myfleetcenter/shared/invoicelookup.aspx?sid=${this.sessionId}&lang=en-US`
      );
    }
  }
};
</script>

<style lang="scss" scoped></style>
