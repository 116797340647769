<template>
  <div class="overflow-y-scroll w-full p-2">
    <div class="flex min-w-full p-2 m-2">
      <div class="flex-grow text-2xl">Turned Down</div>
      <div v-if="error" class="text-red-500 text-center">{{ error }}</div>
      <div class="flex-grow text-right">
        <!-- <a href="/apps/admin/invoices" class="text-indigo-600 m-1 p-1 text-sm"
          >View All <i class="fa fa-share"></i
        ></a> -->
      </div>
    </div>
    <table class="w-full overflow-y-scroll divide-y divide-gray-200">
      <thead class="bg-gray-50">
        <tr>
          <th
            scope="col"
            class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Actions
          </th>
          <th
            scope="col"
            class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            License
          </th>
          <th
            scope="col"
            class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Year
          </th>
          <th
            scope="col"
            class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Model
          </th>
          <th
            scope="col"
            class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Mileage
          </th>
          <th
            scope="col"
            class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Service
          </th>
          <th
            scope="col"
            class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Turned Down
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(service, index) in turnedDownServices"
          :key="service.guid"
          :class="index % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
        >
        <td
            class="whitespace-nowrap text-center text-sm font-medium"
          >
            <button
              v-if="service.dismissed"
              @click="restoreService(service.guid)"
              class="bg-green-500 text-white p-1 m-1 rounded"
            >
              <i class="fa fa-check"></i>
            </button>
            <button v-else @click="dismissService(service.guid)" class="">
              <i class="text-red-500 fa fa-times"></i>
            </button>
            <!-- <a
              :href="`/apps/admin/invoices/${service.invoiceGuid}`"
              class="text-indigo-600 hover:text-indigo-900"
            >
              <i class="text-blue-500 fa fa-eye p-2"></i>
            </a> -->
          </td>
          <td
            class="px-2 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
          >
            {{ service.licensePlate }}
          </td>
          <td class="px-2 py-4 whitespace-nowrap text-sm text-gray-500">
            {{ service.year }}
          </td>
          <td class="px-2 py-4 whitespace-nowrap text-sm text-gray-500">
            {{ service.model }}
          </td>
          <td class="px-2 py-4 whitespace-nowrap text-sm text-gray-500">
            {{ service.mileage }}
          </td>
          <td class="px-2 py-4 whitespace-nowrap text-sm text-gray-500">
            {{ service.categoryDescription }}
          </td>
          <td class="px-2 py-4 whitespace-nowrap text-sm text-gray-500">
            {{
              service.invoiceDate
                ? new Date(service.invoiceDate).toLocaleDateString("en-US")
                : ""
            }}
          </td>
        </tr>
      </tbody>
    </table>
    <div class="flex justify-center my-4 text-xs">
      <button
        @click="goToPreviousPage"
        :disabled="currentPage === 1"
        class="text-blue-500 disabled:text-gray-400"
      >
        Previous
      </button>
      <span class="p-2 w-full text-center">Page {{ currentPage }}</span>
      <button @click="goToNextPage" class="text-blue-500">
        Next
      </button>
    </div>
  </div>
</template>

<script>
//import { reactive, ref, toRefs } from "vue";
import { useStore } from "vuex";
import { computed, ref, onMounted } from "vue";
import axios from "axios";

export default {
  setup() {
    let store = useStore();
    const turnedDownServices = computed(
      () => store.state.turndownServices.results || []
    );

    const currentPage = ref(1);
    const pageSize = ref(5);
    const error = ref(null);

    const fetchTurndownServices = async () => {
      try {
        const response = await axios.post(
          `https://development.api.myfleet.services/api/v0/dashboard/fleets/${store.state.sessionId}/turndown-services`,
          {
            start: (currentPage.value - 1) * pageSize.value + 1,
            length: pageSize.value,
            columns: [{ data: "nextServiceDate", name: "nextServiceDate" }],
            order: [{ dir: "asc" }],
            draw: currentPage.value,
            showPastDueServiceIntervals: true,
            dismissedValue: -1
          }
        );
        store.commit("setTurndownServices", response.data);
      } catch (err) {
        error.value = "Failed to load Turndown Services";
      }
    };

    const goToNextPage = () => {
      currentPage.value++;
      fetchTurndownServices();
    };

    const goToPreviousPage = () => {
      if (currentPage.value > 1) {
        currentPage.value--;
        fetchTurndownServices();
      }
    };

    const restoreService = async serviceGuid => {
      try {
        await axios.patch(
          `https://development.api.myfleet.services/api/v0/dashboard/fleets/${store.state.sessionId}/turndown-services/restore/${serviceGuid}`
        );
        fetchTurndownServices(); // Refresh the data
      } catch (err) {
        error.value = "Failed to restore service";
      }
    };

    const dismissService = async serviceGuid => {
      try {
        await axios.patch(
          `https://development.api.myfleet.services/api/v0/dashboard/fleets/${store.state.sessionId}/turndown-services/dismiss/${serviceGuid}`
        );
        fetchTurndownServices(); // Refresh the data
      } catch (err) {
        error.value = "Failed to dismiss service";
      }
    };

    onMounted(fetchTurndownServices);

    return {
      turnedDownServices,
      goToNextPage,
      goToPreviousPage,
      dismissService,
      restoreService,
      currentPage,
      pageSize,
      error
    };
  }
};
</script>

<style lang="scss" scoped></style>
