import { createStore } from "vuex";
//import * as LSConstants from "./../constants/LocalStorageConstants";
import VuexPersist from "vuex-persist";
const vuexPersist = new VuexPersist({
  key: "mfc-fleets",
  storage: window.localStorage
});
const store = createStore({
  state() {
    return {
      loading: false,
      errors: [],
      authToken: "",
      userName: "",
      userId: "",
      sessionId: "",
      userToken: "",
      userPermissions: [],
      searchResults: [],
      invoices: [],
      selectedInvoice: null,
      selectedInvoices: null,
      turndownServices: [],
      payments: [],
      selectedPayment: null,
      checks: [],
      selectedCheck: null,
      refunds: [],
      selectedRefund: null,
      credits: [],
      selectedCredit: null,
      notes: [],
      selectedNote: null,
      selectedBrand: "",
      fleetId: 0,
      earlyPayTotalDue: 0,
      earlyPayInvoiceCount: 0,
      totalDue: 0,
      invoiceCount: 0,
      dueDate: null,
      creditAvailable: 0,
      pastDueTotal: 0,
      overdueInvoiceCount: 0
    };
  },
  mutations: {
    startLoading(state) {
      state.loading = true;
    },
    stopLoading(state) {
      state.loading = false;
    },
    addError(state, error) {
      this.errors.push(error);
    },
    removeError(index) {
      this.errors = this.errors.splice(index, 1);
    },
    clearErrors() {
      this.errors = [];
    },
    setAuthToken(state, token) {
      state.authToken = token;
    },
    setSessionId(state, sessionId) {
      state.sessionId = sessionId;
    },
    setUserToken(state, userToken) {
      state.userToken = userToken;
    },
    setUsername(state, username) {
      state.userName = username;
    },
    setUserId(state, id) {
      state.userId = id;
    },
    setUserPermissions(state, permissionsArray) {
      state.permissions = permissionsArray;
    },
    setSearchResults(state, results) {
      state.searchResults = results;
    },
    setInvoices(state, invoices) {
      state.invoices = invoices;
    },
    clearInvoices(state) {
      state.invoices = [];
    },
    setselectedInvoice(state, selectedInvoice) {
      state.selectedInvoice = selectedInvoice;
    },
    setPayments(state, payments) {
      state.payments = payments;
    },
    setselectedPayment(state, selectedPayment) {
      state.selectedPayment = selectedPayment;
    },
    setChecks(state, checks) {
      state.checks = checks;
    },
    setselectedCheck(state, selectedCheck) {
      state.selectedCheck = selectedCheck;
    },
    setRefunds(state, refunds) {
      state.refunds = refunds;
    },
    setselectedRefund(state, selectedRefund) {
      state.selectedRefund = selectedRefund;
    },
    setCredits(state, credits) {
      state.credits = credits;
    },
    setselectedCredit(state, selectedCredit) {
      state.selectedCredit = selectedCredit;
    },
    setNotes(state, notes) {
      state.notes = notes;
    },
    setselectedNote(state, selectedNote) {
      state.selectedNote = selectedNote;
    },
    setSelectedBrand(state, brand) {
      state.selectedBrand = brand;
    },
    setFleetId(state, id) {
      state.fleetId = id;
    },
    setEarlyPayTotalDue(state, total) {
      state.earlyPayTotalDue = total;
    },
    setEarlyPayInvoiceCount(state, count) {
      state.earlyPayInvoiceCount = count;
    },
    setTotalDue(state, _totalDue) {
      state.totalDue = _totalDue;
    },
    setInvoiceCount(state, count) {
      state.invoiceCount = count;
    },
    setDueDate(state, due) {
      state.dueDate = due;
    },
    setCreditAvailable(state, creditAvailable) {
      state.creditAvailable = creditAvailable;
    },
    setPastDueTotal(state, total) {
      state.pastDueTotal = total;
    },
    setOverdueInvoiceCount(state, count) {
      state.overdueInvoiceCount = count;
    },
    setFleetSummary(state, fleetSummary) {
      state.fleetSummary = fleetSummary;
    },
    setTurndownServices(state, turndownServices) {
      state.turndownServices = turndownServices;
    }
  },
  actions: {
    storeLoginResponse({ commit }, { username, sessionId }) {
      console.log("User Logged:", username, sessionId);
      commit("setSessionId", sessionId);
      commit("setUserId", username);
    }
  },
  plugins: [vuexPersist.plugin]
});
export default store;
