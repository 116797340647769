<template>
  <div class="w-full">
    <div class="flex w-full">
      <brand-selection></brand-selection>
      <search-bar></search-bar>
    </div>
    <div class="flex-col w-full">
      <button @click="fetchClicked">refresh invoices</button>
      <list></list>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import { mapState, useStore } from "vuex";
import SearchBar from "../../../../components/search/SearchBar.vue";
import BrandSelection from "../../../../components/search/BrandSelection.vue";
import List from "../../../../components/invoices/InvoiceList.vue";
//import Invoice from "./../../../../models/Invoice.js";
import axios from 'axios'
export default {
  components: { SearchBar, BrandSelection },
  async setup() {
    let store = useStore();

    let checkCurrentInvoices = store => {
      if (store.state.invoices.length > 0) {
        return true;
      } else {
        return false;
      }
    };

    let requiresFetch = !checkCurrentInvoices(store);
    // const fetchInvoices = async store => {
    //   let invoices = await Invoice.list(store);
    //   console.log(invoices);
    //    store.commit("setInvoices", invoices.results);
    //   if (typeof invoices === typeof Error) {
    //     store.commit("addError", invoices);
    //   }
    //   return invoices;
    // };
    if (requiresFetch) {
      //await fetchInvoices(store);
    }
    const state = reactive({
      count: 0
    });

    return {
      List,
      //fetchInvoices,
      ...toRefs(state)
    };
  },
  methods: {
    async fetchClicked() {
      // let _invoices = await this.fetchInvoices(this.$store);
      // console.log(_invoices["results"]);
      // this.$store.commit("setInvoices", _invoices["results"]);
      let response = null;
      let b = {
        "dateFilterType": "none",
        "records": "20",
        "orderBy": "Invoice_Date",
        "userType": "customer",
        "endDate": "",
        "drawCount": "1",
        "equivalence": "",
        "sortDir": "asc",
        "startDate": "",
        "filterType": "none",
        "accountGuid": "281BAB5C-15E8-49AF-920C-9EC59909077E",
        "groupBy": "none",
        "pageNumber": "1",
        "filterValues": "",
        "invoiceStatus": "all"
      };
      const headers = {
        accept: "application/json",
        "Content-Type": "multipart/form-data"
      };
      try {
        let endpoint = 'https://development.api.myfleet.services/api/v0/search/deprecated/users/invoices-anonymous';
        response = await axios.post(endpoint, b, headers);
      } catch (err) {
        alert(err);
      }
      console.log(response)
    }
  },
  computed: {
    ...mapState(["invoices"])
  }
};
</script>

<style scoped></style>
