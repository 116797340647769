<template>
  <div class="bg-black flex-1 space-y-10">
    <centered-accordion></centered-accordion>
    <offset-with-supporting-text></offset-with-supporting-text>
    <side-by-side></side-by-side>
    <three-column></three-column>
    <two-column-on-brand></two-column-on-brand>
    <two-columns></two-columns>
    <two-columns-with-description></two-columns-with-description>
    <two-column-with-description-dark></two-column-with-description-dark>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import CenteredAccordion from "./../../../components/tailwind-base/marketing/faq/CenteredAccordion.vue";
import OffsetWithSupportingText from "./../../../components/tailwind-base/marketing/faq/OffsetWithSupportingText.vue";
import SideBySide from "./../../../components/tailwind-base/marketing/faq/SideBySide.vue";
import ThreeColumn from "./../../../components/tailwind-base/marketing/faq/ThreeColumn.vue";
import TwoColumnOnBrand from "./../../../components/tailwind-base/marketing/faq/TwoColumnOnBrand.vue";
import TwoColumns from "./../../../components/tailwind-base/marketing/faq/TwoColumns.vue";
import TwoColumnsWithDescription from "./../../../components/tailwind-base/marketing/faq/TwoColumnsWithDescription.vue";
import TwoColumnWithDescriptionDark from "./../../../components/tailwind-base/marketing/faq/TwoColumnWithDescriptionDark.vue";

export default {
  components: {
    CenteredAccordion,
    OffsetWithSupportingText,
    SideBySide,
    ThreeColumn,
    TwoColumnOnBrand,
    TwoColumns,
    TwoColumnsWithDescription,
    TwoColumnWithDescriptionDark
  },
  setup() {
    const state = reactive({
      count: 0
    });

    return {
      ...toRefs(state)
    };
  }
};
</script>

<style lang="scss" scoped></style>
