let axios = require("axios");
let { useStore } = require("vuex");

function API() {
  this.base_url =
    "https://https://development.api.myfleet.services/api/v0/search/deprecated/users/invoices-anonymous"; //"http://localhost:3000/api/v1";
  this.search_url = "/search/deprecated/users/'";

  //Search
  this.fetchSearchResults = async function(terms) {
    let resp = null;
    let store = useStore();
    try {
      let endpoint = this.search_url + terms;
      resp = await axios.get(endpoint, {
        headers: {
          authorization: store.authToken
        }
      });
      store.commit("searchResults", resp.data);
    } catch (err) {
      resp = err;
    }
    return resp;
  };

  //Invoices
  this.fetchInvoice = async function(id) {
    let response = null;
    try {
      let store = useStore();
      let endpoint = `${this.base_url}/invoices/${id}`;
      response = await axios.get(endpoint, {
        headers: {
          authorization: store.authToken
        }
      });
      store.commit("setAuthToken", response.token);
    } catch (err) {
      response = err;
    }
    return response;
  };

  this.putInvoice = async function(id, invoice) {
    let response = null;
    const updatedInvoice = {
      id: id,
      amount: invoice.amount
    };
    try {
      let store = useStore();
      let endpoint = `${this.base_url}/invoices/${id}`;
      response = await axios.put(endpoint, {
        headers: {
          authorization: store.authToken
        },
        updatedInvoice
      });
      store.commit("setAuthToken", response.token);
    } catch (err) {
      response = err;
    }
    return response;
  };

  this.fetchInvoices = async function(store) {
    let response = null;
    let b = {
      dateFilterType: "none",
      records: "20",
      orderBy: "Invoice_Date",
      userType: "customer",
      endDate: "",
      drawCount: "1",
      equivalence: "",
      sortDir: "asc",
      startDate: "",
      filterType: "none",
      accountGuid: "281BAB5C-15E8-49AF-920C-9EC59909077E",
      groupBy: "none",
      pageNumber: "1",
      filterValues: "",
      invoiceStatus: "all"
    };
    const headers = {
      accept: "application/json",
      "Content-Type": "multipart/form-data"
    };
    try {
      let endpoint = `${this.base_url}`;
      response = await axios.post(endpoint, b, headers);
    } catch (err) {
      response = {
        recordsTotal: 40,
        recordsFiltered: 40,
        draw: 1,
        results: [
          {
            invoiceId: "8416490",
            invoiceGuid: "CC22707E-ECE1-4EC8-93E4-293FC8C78667",
            invoiceNumber: "8092595",
            importDate: "11/2/2021 4:28:56 PM",
            serviceDate: "11/2/2021 4:28:23 PM",
            paidOn: "11/22/2021 12:00:00 AM",
            customer: "KJ Auto Experts II LLC (Midas USA)",
            customerId: "4633",
            customerGuid: "281BAB5C-15E8-49AF-920C-9EC59909077E",
            store: "MDS05336",
            fleetId: "145627",
            fleetCode: "MDS-145627",
            fleetGuid: "A283E097-1260-43E2-8BFE-DAFF6C103C9A",
            fleet: "Airport Van Rental",
            driver: "",
            make: "DODGE",
            model: "GRAND CARAVAN",
            year: "2018",
            license: "8CSN846",
            vin: "2C4RDGCGXJR192595",
            invoiceTotal: "736.9100",
            prepaid: "0.0000",
            discounts: "0.0000",
            adjustments: "0.0000",
            payments: "736.9100",
            balance: "0.0000",
            pending: false,
            hasNotes: false,
            hasPendingPayments: false
          },
          {
            invoiceId: "8418385",
            invoiceGuid: "7A1009DD-F560-4766-8886-8F668D71B4C9",
            invoiceNumber: "8092366",
            importDate: "11/3/2021 11:41:03 AM",
            serviceDate: "11/3/2021 11:40:39 AM",
            paidOn: "11/19/2021 7:36:47 AM",
            customer: "KJ Auto Experts II LLC (Midas USA)",
            customerId: "4633",
            customerGuid: "281BAB5C-15E8-49AF-920C-9EC59909077E",
            store: "MDS05336",
            fleetId: "138141",
            fleetCode: "MDS-138141",
            fleetGuid: "0F123FDD-A6CE-4776-BCA8-1C5AC841BD0D",
            fleet: "Merchants Fleet Management",
            driver: "",
            make: "FORD",
            model: "TRANSIT CONNECT",
            year: "2019",
            license: "LYD7454",
            vin: "NM0LS7E76K1400290",
            invoiceTotal: "106.8800",
            prepaid: "0.0000",
            discounts: "-10.6900",
            adjustments: "0.0000",
            payments: "96.1900",
            balance: "0.0000",
            pending: false,
            hasNotes: false,
            hasPendingPayments: false
          },
          {
            invoiceId: "8419046",
            invoiceGuid: "6612DADD-142E-4389-97DC-55B9284A2C9F",
            invoiceNumber: "8092596",
            importDate: "11/3/2021 1:39:49 PM",
            serviceDate: "11/3/2021 1:39:06 PM",
            paidOn: "11/22/2021 12:00:00 AM",
            customer: "KJ Auto Experts II LLC (Midas USA)",
            customerId: "4633",
            customerGuid: "281BAB5C-15E8-49AF-920C-9EC59909077E",
            store: "MDS05336",
            fleetId: "145627",
            fleetCode: "MDS-145627",
            fleetGuid: "A283E097-1260-43E2-8BFE-DAFF6C103C9A",
            fleet: "Airport Van Rental",
            driver: "",
            make: "DODGE",
            model: "GRAND CARAVAN",
            year: "2018",
            license: "8CSN828",
            vin: "2C4RDGCG9JR251328",
            invoiceTotal: "1295.1100",
            prepaid: "0.0000",
            discounts: "0.0000",
            adjustments: "0.0000",
            payments: "1295.1100",
            balance: "0.0000",
            pending: false,
            hasNotes: false,
            hasPendingPayments: false
          },
          {
            invoiceId: "8419081",
            invoiceGuid: "4E72D006-A544-4017-BFA9-0FE8DCBEE9C5",
            invoiceNumber: "8092592",
            importDate: "11/3/2021 4:19:20 PM",
            serviceDate: "11/3/2021 4:18:56 PM",
            paidOn: "11/22/2021 12:00:00 AM",
            customer: "KJ Auto Experts II LLC (Midas USA)",
            customerId: "4633",
            customerGuid: "281BAB5C-15E8-49AF-920C-9EC59909077E",
            store: "MDS05336",
            fleetId: "145627",
            fleetCode: "MDS-145627",
            fleetGuid: "A283E097-1260-43E2-8BFE-DAFF6C103C9A",
            fleet: "Airport Van Rental",
            driver: "",
            make: "FORD",
            model: "TRANSIT VAN T350",
            year: "2017",
            license: "7TCL978",
            vin: "1FBZX2ZM7HKA46093",
            invoiceTotal: "949.9200",
            prepaid: "0.0000",
            discounts: "0.0000",
            adjustments: "0.0000",
            payments: "949.9200",
            balance: "0.0000",
            pending: false,
            hasNotes: false,
            hasPendingPayments: false
          },
          {
            invoiceId: "8419084",
            invoiceGuid: "FE0773C8-4B20-4CAC-9B02-18F5E812D582",
            invoiceNumber: "8092597",
            importDate: "11/3/2021 4:38:28 PM",
            serviceDate: "11/3/2021 4:21:13 PM",
            paidOn: "11/22/2021 12:00:00 AM",
            customer: "KJ Auto Experts II LLC (Midas USA)",
            customerId: "4633",
            customerGuid: "281BAB5C-15E8-49AF-920C-9EC59909077E",
            store: "MDS05336",
            fleetId: "145627",
            fleetCode: "MDS-145627",
            fleetGuid: "A283E097-1260-43E2-8BFE-DAFF6C103C9A",
            fleet: "Airport Van Rental",
            driver: "",
            make: "FORD",
            model: "TRANSIT VAN T350 HD",
            year: "2018",
            license: "MHP3734",
            vin: "1FBZX2YM1JKA97192",
            invoiceTotal: "621.9800",
            prepaid: "0.0000",
            discounts: "0.0000",
            adjustments: "0.0000",
            payments: "621.9800",
            balance: "0.0000",
            pending: false,
            hasNotes: false,
            hasPendingPayments: false
          },
          {
            invoiceId: "8421235",
            invoiceGuid: "36DDB91A-27E2-4C3D-BE07-EAC5F950F775",
            invoiceNumber: "8092611",
            importDate: "11/4/2021 1:22:45 PM",
            serviceDate: "11/4/2021 1:20:27 PM",
            paidOn: "11/22/2021 12:00:00 AM",
            customer: "KJ Auto Experts II LLC (Midas USA)",
            customerId: "4633",
            customerGuid: "281BAB5C-15E8-49AF-920C-9EC59909077E",
            store: "MDS05336",
            fleetId: "145627",
            fleetCode: "MDS-145627",
            fleetGuid: "A283E097-1260-43E2-8BFE-DAFF6C103C9A",
            fleet: "Airport Van Rental",
            driver: "",
            make: "GMCT",
            model: "SAVANA 3500",
            year: "2018",
            license: "8EJC506",
            vin: "1GJZ7PFGXJ1240600",
            invoiceTotal: "134.8100",
            prepaid: "0.0000",
            discounts: "0.0000",
            adjustments: "0.0000",
            payments: "134.8100",
            balance: "0.0000",
            pending: false,
            hasNotes: false,
            hasPendingPayments: false
          },
          {
            invoiceId: "8425283",
            invoiceGuid: "459E4F7F-F44E-4C7E-9A1C-775CADF883A3",
            invoiceNumber: "8092612",
            importDate: "11/5/2021 1:18:02 PM",
            serviceDate: "11/4/2021 3:47:36 PM",
            paidOn: "11/22/2021 12:00:00 AM",
            customer: "KJ Auto Experts II LLC (Midas USA)",
            customerId: "4633",
            customerGuid: "281BAB5C-15E8-49AF-920C-9EC59909077E",
            store: "MDS05336",
            fleetId: "145627",
            fleetCode: "MDS-145627",
            fleetGuid: "A283E097-1260-43E2-8BFE-DAFF6C103C9A",
            fleet: "Airport Van Rental",
            driver: "",
            make: "FORD",
            model: "TRANSIT VAN T350",
            year: "2017",
            license: "7TCL978",
            vin: "1FBZX2ZM7HKA46093",
            invoiceTotal: "585.9400",
            prepaid: "0.0000",
            discounts: "0.0000",
            adjustments: "0.0000",
            payments: "585.9400",
            balance: "0.0000",
            pending: false,
            hasNotes: false,
            hasPendingPayments: false
          },
          {
            invoiceId: "8428089",
            invoiceGuid: "3A90AB45-FA9D-4CC4-8F0D-ADE45A8EA70F",
            invoiceNumber: "8092627",
            importDate: "11/7/2021 3:27:10 AM",
            serviceDate: "11/6/2021 1:02:43 PM",
            paidOn: "11/9/2021 11:41:36 AM",
            customer: "KJ Auto Experts II LLC (Midas USA)",
            customerId: "4633",
            customerGuid: "281BAB5C-15E8-49AF-920C-9EC59909077E",
            store: "MDS05336",
            fleetId: "119157",
            fleetCode: "AIN-4",
            fleetGuid: "11E0AE92-72EF-476F-A2AB-7BBC13ADB9DA",
            fleet: "Enterprise (Auto Integrate)",
            driver: "",
            make: "ram",
            model: "1500 classic",
            year: "2019",
            license: "msn1005",
            vin: "1c6rr6ft8ks552586",
            invoiceTotal: "96.5200",
            prepaid: "0.0000",
            discounts: "-9.0000",
            adjustments: "0.0000",
            payments: "87.5200",
            balance: "0.0000",
            pending: false,
            hasNotes: false,
            hasPendingPayments: false
          },
          {
            invoiceId: "8436759",
            invoiceGuid: "54B2C649-CF9A-4AF4-B8F3-1423B3F6B4CE",
            invoiceNumber: "8092652",
            importDate: "11/10/2021 10:11:45 AM",
            serviceDate: "11/10/2021 10:11:18 AM",
            paidOn: "11/24/2021 4:11:57 PM",
            customer: "KJ Auto Experts II LLC (Midas USA)",
            customerId: "4633",
            customerGuid: "281BAB5C-15E8-49AF-920C-9EC59909077E",
            store: "MDS05336",
            fleetId: "138141",
            fleetCode: "MDS-138141",
            fleetGuid: "0F123FDD-A6CE-4776-BCA8-1C5AC841BD0D",
            fleet: "Merchants Fleet Management",
            driver: "",
            make: "HONDA",
            model: "FIT",
            year: "2017",
            license: "KWP2773",
            vin: "JHMGK5H58HS018055",
            invoiceTotal: "74.8500",
            prepaid: "0.0000",
            discounts: "-7.0000",
            adjustments: "0.0000",
            payments: "67.8500",
            balance: "0.0000",
            pending: false,
            hasNotes: false,
            hasPendingPayments: false
          },
          {
            invoiceId: "8440920",
            invoiceGuid: "B032FCF4-8B48-4EC6-9F72-D2D096C74CCF",
            invoiceNumber: "8092666",
            importDate: "11/12/2021 11:56:12 AM",
            serviceDate: "11/12/2021 11:54:20 AM",
            paidOn: "11/26/2021 12:00:00 AM",
            customer: "KJ Auto Experts II LLC (Midas USA)",
            customerId: "4633",
            customerGuid: "281BAB5C-15E8-49AF-920C-9EC59909077E",
            store: "MDS05336",
            fleetId: "145627",
            fleetCode: "MDS-145627",
            fleetGuid: "A283E097-1260-43E2-8BFE-DAFF6C103C9A",
            fleet: "Airport Van Rental",
            driver: "",
            make: "DODGE",
            model: "GRAND CARAVAN",
            year: "2018",
            license: "KYM235",
            vin: "2C4RDGEGXJR195350",
            invoiceTotal: "109.4300",
            prepaid: "0.0000",
            discounts: "0.0000",
            adjustments: "0.0000",
            payments: "109.4300",
            balance: "0.0000",
            pending: false,
            hasNotes: false,
            hasPendingPayments: false
          },
          {
            invoiceId: "8443256",
            invoiceGuid: "CBBB6AD5-8EDC-4E6C-AC30-A8C7765E4B4E",
            invoiceNumber: "8092663",
            importDate: "11/13/2021 3:27:23 AM",
            serviceDate: "11/12/2021 1:30:55 PM",
            paidOn: "11/23/2021 10:06:21 AM",
            customer: "KJ Auto Experts II LLC (Midas USA)",
            customerId: "4633",
            customerGuid: "281BAB5C-15E8-49AF-920C-9EC59909077E",
            store: "MDS05336",
            fleetId: "119157",
            fleetCode: "AIN-4",
            fleetGuid: "11E0AE92-72EF-476F-A2AB-7BBC13ADB9DA",
            fleet: "Enterprise (Auto Integrate)",
            driver: "",
            make: "ford",
            model: "e-150",
            year: "2007",
            license: "",
            vin: "1fbss31l37da55109",
            invoiceTotal: "291.6900",
            prepaid: "0.0000",
            discounts: "-28.5800",
            adjustments: "0.0000",
            payments: "263.1100",
            balance: "0.0000",
            pending: false,
            hasNotes: false,
            hasPendingPayments: false
          },
          {
            invoiceId: "8444012",
            invoiceGuid: "BBA53DC2-93CC-42F4-A22F-0755039011D5",
            invoiceNumber: "8092670",
            importDate: "11/13/2021 9:55:09 AM",
            serviceDate: "11/13/2021 9:54:38 AM",
            paidOn: "12/2/2021 12:18:45 PM",
            customer: "KJ Auto Experts II LLC (Midas USA)",
            customerId: "4633",
            customerGuid: "281BAB5C-15E8-49AF-920C-9EC59909077E",
            store: "MDS05336",
            fleetId: "140992",
            fleetCode: "MDS-140992",
            fleetGuid: "A98C814C-01F2-452C-BB07-8FBE83A9F4FC",
            fleet: "MAP/Wheels",
            driver: "",
            make: "FORD",
            model: "F250 SUPER DUTY P/U",
            year: "2016",
            license: "HXT9056",
            vin: "1FD7X2A6XGED48308",
            invoiceTotal: "271.8500",
            prepaid: "0.0000",
            discounts: "-26.9900",
            adjustments: "0.0000",
            payments: "244.8600",
            balance: "0.0000",
            pending: false,
            hasNotes: false,
            hasPendingPayments: false
          },
          {
            invoiceId: "8444246",
            invoiceGuid: "3EC81293-5C55-4A70-9841-72E96D7F20C9",
            invoiceNumber: "8092673",
            importDate: "11/13/2021 3:11:55 PM",
            serviceDate: "11/13/2021 3:11:33 PM",
            paidOn: "11/24/2021 4:11:57 PM",
            customer: "KJ Auto Experts II LLC (Midas USA)",
            customerId: "4633",
            customerGuid: "281BAB5C-15E8-49AF-920C-9EC59909077E",
            store: "MDS05336",
            fleetId: "138141",
            fleetCode: "MDS-138141",
            fleetGuid: "0F123FDD-A6CE-4776-BCA8-1C5AC841BD0D",
            fleet: "Merchants Fleet Management",
            driver: "",
            make: "HONDA",
            model: "FIT",
            year: "2013",
            license: "LJK9126",
            vin: "JHMGE8H30DC061249",
            invoiceTotal: "25.5000",
            prepaid: "0.0000",
            discounts: "0.0000",
            adjustments: "0.0000",
            payments: "25.5000",
            balance: "0.0000",
            pending: false,
            hasNotes: false,
            hasPendingPayments: false
          },
          {
            invoiceId: "8453543",
            invoiceGuid: "F27BAB57-A87C-4E7C-9FA2-C0D5B2ABFE11",
            invoiceNumber: "8092693",
            importDate: "11/18/2021 3:22:16 AM",
            serviceDate: "11/17/2021 9:50:27 AM",
            paidOn: "11/23/2021 10:06:21 AM",
            customer: "KJ Auto Experts II LLC (Midas USA)",
            customerId: "4633",
            customerGuid: "281BAB5C-15E8-49AF-920C-9EC59909077E",
            store: "MDS05336",
            fleetId: "119157",
            fleetCode: "AIN-4",
            fleetGuid: "11E0AE92-72EF-476F-A2AB-7BBC13ADB9DA",
            fleet: "Enterprise (Auto Integrate)",
            driver: "",
            make: "chevrolet",
            model: "city express",
            year: "2015",
            license: "gzr0561",
            vin: "3n63m0yn0fk709771",
            invoiceTotal: "1326.6100",
            prepaid: "0.0000",
            discounts: "-132.6600",
            adjustments: "0.0000",
            payments: "1193.9500",
            balance: "0.0000",
            pending: false,
            hasNotes: false,
            hasPendingPayments: false
          },
          {
            invoiceId: "8454100",
            invoiceGuid: "F802E6D9-DD72-4F2D-A2FB-80002DD1AE3C",
            invoiceNumber: "8092698",
            importDate: "11/18/2021 3:28:55 AM",
            serviceDate: "11/17/2021 4:38:13 PM",
            paidOn: "11/23/2021 10:06:21 AM",
            customer: "KJ Auto Experts II LLC (Midas USA)",
            customerId: "4633",
            customerGuid: "281BAB5C-15E8-49AF-920C-9EC59909077E",
            store: "MDS05336",
            fleetId: "119157",
            fleetCode: "AIN-4",
            fleetGuid: "11E0AE92-72EF-476F-A2AB-7BBC13ADB9DA",
            fleet: "Enterprise (Auto Integrate)",
            driver: "",
            make: "chevrolet",
            model: "city express",
            year: "2015",
            license: "gzr0561",
            vin: "3n63m0yn0fk709771",
            invoiceTotal: "40.6900",
            prepaid: "0.0000",
            discounts: "-4.0700",
            adjustments: "0.0000",
            payments: "36.6200",
            balance: "0.0000",
            pending: false,
            hasNotes: false,
            hasPendingPayments: false
          },
          {
            invoiceId: "8459747",
            invoiceGuid: "B7E924FC-773F-45BA-89FC-0755D713BE0E",
            invoiceNumber: "8092711",
            importDate: "11/20/2021 3:29:54 AM",
            serviceDate: "11/19/2021 3:02:33 PM",
            paidOn: "12/15/2021 7:03:34 AM",
            customer: "KJ Auto Experts II LLC (Midas USA)",
            customerId: "4633",
            customerGuid: "281BAB5C-15E8-49AF-920C-9EC59909077E",
            store: "MDS05336",
            fleetId: "118979",
            fleetCode: "AIN-5",
            fleetGuid: "42BD22E1-73CB-4077-BF73-50748FEE3D0C",
            fleet: "Donlen (Auto Integrate)",
            driver: "",
            make: "toyota",
            model: "prius 5d fwd hatch",
            year: "2019",
            license: "mkp9878",
            vin: "jtdkarfu1k3088711",
            invoiceTotal: "80.3900",
            prepaid: "0.0000",
            discounts: "-7.5100",
            adjustments: "0.0000",
            payments: "72.8800",
            balance: "0.0000",
            pending: false,
            hasNotes: false,
            hasPendingPayments: false
          },
          {
            invoiceId: "8463154",
            invoiceGuid: "C9F8E92E-017B-4F7E-A626-81B0F41A784C",
            invoiceNumber: "8092723",
            importDate: "11/22/2021 2:45:22 PM",
            serviceDate: "11/22/2021 2:45:00 PM",
            paidOn: "12/8/2021 12:00:00 AM",
            customer: "KJ Auto Experts II LLC (Midas USA)",
            customerId: "4633",
            customerGuid: "281BAB5C-15E8-49AF-920C-9EC59909077E",
            store: "MDS05336",
            fleetId: "145627",
            fleetCode: "MDS-145627",
            fleetGuid: "A283E097-1260-43E2-8BFE-DAFF6C103C9A",
            fleet: "Airport Van Rental",
            driver: "",
            make: "GMCT",
            model: "SAVANA 3500",
            year: "2018",
            license: "8EJC506",
            vin: "1GJZ7PFGXJ1240600",
            invoiceTotal: "29.6700",
            prepaid: "0.0000",
            discounts: "0.0000",
            adjustments: "0.0000",
            payments: "29.6700",
            balance: "0.0000",
            pending: false,
            hasNotes: false,
            hasPendingPayments: false
          },
          {
            invoiceId: "8466286",
            invoiceGuid: "35FA5F27-8AE4-4A4E-A0D0-8134DC87E399",
            invoiceNumber: "8092724",
            importDate: "11/23/2021 10:49:22 AM",
            serviceDate: "11/23/2021 10:48:59 AM",
            paidOn: "12/8/2021 12:00:00 AM",
            customer: "KJ Auto Experts II LLC (Midas USA)",
            customerId: "4633",
            customerGuid: "281BAB5C-15E8-49AF-920C-9EC59909077E",
            store: "MDS05336",
            fleetId: "145627",
            fleetCode: "MDS-145627",
            fleetGuid: "A283E097-1260-43E2-8BFE-DAFF6C103C9A",
            fleet: "Airport Van Rental",
            driver: "",
            make: "FORD",
            model: "TRANSIT VAN T350 HD",
            year: "2018",
            license: "BN50002",
            vin: "1FBZX2YG0JKA72715",
            invoiceTotal: "338.6000",
            prepaid: "0.0000",
            discounts: "0.0000",
            adjustments: "0.0000",
            payments: "338.6000",
            balance: "0.0000",
            pending: false,
            hasNotes: false,
            hasPendingPayments: false
          },
          {
            invoiceId: "8469341",
            invoiceGuid: "CB70F6C0-A89C-4DD5-AAB6-0636FC6A5CE9",
            invoiceNumber: "8092739",
            importDate: "11/24/2021 1:22:49 PM",
            serviceDate: "11/24/2021 1:22:14 PM",
            paidOn: "12/8/2021 12:00:00 AM",
            customer: "KJ Auto Experts II LLC (Midas USA)",
            customerId: "4633",
            customerGuid: "281BAB5C-15E8-49AF-920C-9EC59909077E",
            store: "MDS05336",
            fleetId: "145627",
            fleetCode: "MDS-145627",
            fleetGuid: "A283E097-1260-43E2-8BFE-DAFF6C103C9A",
            fleet: "Airport Van Rental",
            driver: "",
            make: "NIST",
            model: "QUEST MINIVAN",
            year: "2017",
            license: "8LTY387",
            vin: "JN8AE2KP2H9170190",
            invoiceTotal: "305.7300",
            prepaid: "0.0000",
            discounts: "0.0000",
            adjustments: "0.0000",
            payments: "305.7300",
            balance: "0.0000",
            pending: false,
            hasNotes: false,
            hasPendingPayments: false
          },
          {
            invoiceId: "8475583",
            invoiceGuid: "FBF1CD13-D46E-41A0-801E-7731A68D0D2F",
            invoiceNumber: "8092759",
            importDate: "11/30/2021 3:21:53 AM",
            serviceDate: "11/29/2021 9:42:35 AM",
            paidOn: "12/8/2021 12:56:23 PM",
            customer: "KJ Auto Experts II LLC (Midas USA)",
            customerId: "4633",
            customerGuid: "281BAB5C-15E8-49AF-920C-9EC59909077E",
            store: "MDS05336",
            fleetId: "119157",
            fleetCode: "AIN-4",
            fleetGuid: "11E0AE92-72EF-476F-A2AB-7BBC13ADB9DA",
            fleet: "Enterprise (Auto Integrate)",
            driver: "",
            make: "ford",
            model: "e-150",
            year: "2007",
            license: "",
            vin: "1fbss31l37da55109",
            invoiceTotal: "25.5000",
            prepaid: "0.0000",
            discounts: "0.0000",
            adjustments: "0.0000",
            payments: "25.5000",
            balance: "0.0000",
            pending: false,
            hasNotes: false,
            hasPendingPayments: false
          }
        ]
      };
    }
    console.log(store.authToken);
    console.log(response);
    return response;
  };

  //Fleets
  this.createFleet = async function(fleetOptions) {
    let response;
    try {
      let store = useStore();
      response = await axios.post(`${this.base_url}/fleets/new`, {
        headers: {
          authorization: store.authToken
        },
        fleetOptions
      });
      store.commit("setAuthToken", response.token);
      store.commit("selectedFleet", response.data);
    } catch (err) {
      response = err;
    }
    return response;
  };

  this.fetchFleet = async function(id) {
    let response = null;
    let store = useStore();
    try {
      let endpoint = `${this.base_url}/fleets/${id}`;
      response = await axios.get(endpoint, {
        headers: {
          authorization: store.authToken
        }
      });
      store.commit("setAuthToken", response.token);
    } catch (err) {
      response = err;
    }
    return response;
  };

  this.putFleet = async function(id, fleet) {
    let response = null;
    try {
      let store = useStore();
      let endpoint = `${this.base_url}/fleets/${id}`;
      response = await axios.put(endpoint, {
        headers: {
          authorization: store.authToken
        },
        fleet
      });
      store.commit("setAuthToken", response.token);
    } catch (err) {
      response = err;
    }
    return response;
  };

  this.deleteFleet = async function(id) {
    let response = null;
    try {
      let store = useStore();
      let endpoint = `${this.base_url}/fleets/${id}`;
      response = await axios.delete(endpoint, {
        headers: {
          authorization: store.authToken
        }
      });
      store.commit("setAuthToken", response.token);
    } catch (err) {
      response = err;
    }
    return response;
  };

  //Payments
  this.createPayment = async function(paymentOptions) {
    let response;
    let store = useStore();
    try {
      response = await axios.post(`${this.base_url}/payments/new`, {
        headers: {
          authorization: store.authToken
        },
        paymentOptions
      });
      store.commit("setAuthToken", response.token);
    } catch (err) {
      response = err;
    }
    return response;
  };

  this.fetchPayment = async function(id) {
    let response = null;
    let store = useStore();
    try {
      let endpoint = `${this.base_url}/payments/${id}`;
      response = await axios.get(endpoint, {
        headers: {
          authorization: store.authToken
        }
      });
      store.commit("setAuthToken", response.token);
    } catch (err) {
      response = err;
    }
    return response;
  };

  this.putPayment = async function(id, payment) {
    let response = null;
    try {
      let store = useStore();
      let endpoint = `${this.base_url}/payments/${id}`;
      response = await axios.put(endpoint, {
        headers: {
          authorization: store.authToken
        },
        payment
      });
      store.commit("setAuthToken", response.token);
    } catch (err) {
      response = err;
    }
    return response;
  };

  this.deletePayment = async function(id) {
    let response = null;
    try {
      let store = useStore();
      let endpoint = `${this.base_url}/payments/${id}`;
      response = await axios.delete(endpoint, {
        headers: {
          authorization: store.authToken
        }
      });
      store.commit("setAuthToken", response.token);
    } catch (err) {
      response = err;
    }
    return response;
  };

  //ServiceCenters
  this.createServiceCenter = async function(serviceCenterOptions) {
    let response;
    let store = useStore();
    try {
      response = await axios.post(`${this.base_url}/company-sites/new`, {
        headers: {
          authorization: store.authToken
        },
        serviceCenterOptions
      });
      store.commit("setAuthToken", response.token);
    } catch (err) {
      response = err;
    }
    return response;
  };

  this.fetchServiceCenter = async function(id) {
    let response = null;
    let store = useStore();
    try {
      let endpoint = `${this.base_url}/company-sites/${id}`;
      response = await axios.get(endpoint, {
        headers: {
          authorization: store.authToken
        }
      });
      store.commit("setAuthToken", response.token);
    } catch (err) {
      response = err;
    }
    return response;
  };

  this.putServiceCenter = async function(id, serviceCenter) {
    let response = null;
    try {
      let store = useStore();
      let endpoint = `${this.base_url}/company-sites/${id}`;
      response = await axios.put(endpoint, {
        headers: {
          authorization: store.authToken
        },
        serviceCenter
      });
      store.commit("setAuthToken", response.token);
    } catch (err) {
      response = err;
    }
    return response;
  };

  this.deleteServiceCenter = async function(id) {
    let response = null;
    try {
      let store = useStore();
      let endpoint = `${this.base_url}/company-sites/${id}`;
      response = await axios.delete(endpoint, {
        headers: {
          authorization: store.authToken
        }
      });
      store.commit("setAuthToken", response.token);
    } catch (err) {
      response = err;
    }
    return response;
  };

  //Employees
  this.createEmployee = async function(employeeOptions) {
    let response;
    let store = useStore();
    try {
      response = await axios.post(`${this.base_url}/employees/new`, {
        headers: {
          authorization: store.authToken
        },
        employeeOptions
      });
      store.commit("setAuthToken", response.token);
    } catch (err) {
      response = err;
    }
    return response;
  };

  this.fetchEmployee = async function(id) {
    let response = null;
    let store = useStore();
    try {
      let endpoint = `${this.base_url}/employees/${id}`;
      response = await axios.get(endpoint, {
        headers: {
          authorization: store.authToken
        }
      });
      store.commit("setAuthToken", response.token);
    } catch (err) {
      response = err;
    }
    return response;
  };

  this.putEmployee = async function(id, employee) {
    let response = null;
    try {
      let store = useStore();
      let endpoint = `${this.base_url}/employees/${id}`;
      response = await axios.put(endpoint, {
        headers: {
          authorization: store.authToken
        },
        employee
      });
      store.commit("setAuthToken", response.token);
    } catch (err) {
      response = err;
    }
    return response;
  };

  this.deleteEmployee = async function(id) {
    let response = null;
    try {
      let store = useStore();
      let endpoint = `${this.base_url}/employees/${id}`;
      response = await axios.delete(endpoint, {
        headers: {
          authorization: store.authToken
        }
      });
      store.commit("setAuthToken", response.token);
    } catch (err) {
      response = err;
    }
    return response;
  };

  //Documents
  this.fetchDocument = async function(id) {
    let response = null;
    let store = useStore();
    try {
      let endpoint = `${this.base_url}/documents/${id}`;
      response = await axios.get(endpoint, {
        headers: {
          authorization: store.authToken
        }
      });
      store.commit("setAuthToken", response.token);
    } catch (err) {
      response = err;
    }
    return response;
  };

  this.putDocument = async function(id, document) {
    let response = null;
    try {
      let store = useStore();
      let endpoint = `${this.base_url}/docuements/${id}`;
      response = await axios.put(endpoint, {
        headers: {
          authorization: store.authToken
        },
        document
      });
      store.commit("setAuthToken", response.token);
    } catch (err) {
      response = err;
    }
    return response;
  };

  this.deleteDocument = async function(id) {
    let response = null;
    try {
      let store = useStore();
      let endpoint = `${this.base_url}/documents/${id}`;
      response = await axios.delete(endpoint, {
        headers: {
          authorization: store.authToken
        }
      });
      store.commit("setAuthToken", response.token);
    } catch (err) {
      response = err;
    }
    return response;
  };

  //CorporationType
  this.createCorporationType = async function(corporationTypeOptions) {
    let response;
    let store = useStore();
    try {
      response = await axios.post(`${this.base_url}/corporation-types/new`, {
        headers: {
          authorization: store.authToken
        },
        corporationTypeOptions
      });
      store.commit("setAuthToken", response.token);
    } catch (err) {
      response = err;
    }
    return response;
  };

  this.fetchCorporationType = async function(id) {
    let response = null;
    let store = useStore();
    try {
      let endpoint = `${this.base_url}/corporation-types/${id}`;
      response = await axios.get(endpoint, {
        headers: {
          authorization: store.authToken
        }
      });
      store.commit("setAuthToken", response.token);
    } catch (err) {
      response = err;
    }
    return response;
  };

  this.putCorporationType = async function(id, corporationType) {
    let response = null;
    try {
      let store = useStore();
      let endpoint = `${this.base_url}/corporation-types/${id}`;
      response = await axios.put(endpoint, {
        headers: {
          authorization: store.authToken
        },
        corporationType
      });
      store.commit("setAuthToken", response.token);
    } catch (err) {
      response = err;
    }
    return response;
  };

  this.deleteCorporationType = async function(id) {
    let response = null;
    try {
      let store = useStore();
      let endpoint = `${this.base_url}/corporation-types/${id}`;
      response = await axios.delete(endpoint, {
        headers: {
          authorization: store.authToken
        }
      });
      store.commit("setAuthToken", response.token);
    } catch (err) {
      response = err;
    }
    return response;
  };

  //Customers
  this.createCustomer = async function(customerOptions) {
    let response;
    let store = useStore();
    try {
      response = await axios.post(`${this.base_url}/customers/new`, {
        headers: {
          authorization: store.authToken
        },
        customerOptions
      });
      store.commit("setAuthToken", response.token);
    } catch (err) {
      response = err;
    }
    return response;
  };

  this.fetchCustomer = async function(id) {
    let response = null;
    let store = useStore();
    try {
      let endpoint = `${this.base_url}/customers/${id}`;
      response = await axios.get(endpoint, {
        headers: {
          authorization: store.authToken
        }
      });
      store.commit("setAuthToken", response.token);
    } catch (err) {
      response = err;
    }
    return response;
  };

  this.putCustomer = async function(id, customer) {
    let response = null;
    try {
      let store = useStore();
      let endpoint = `${this.base_url}/customers/${id}`;
      response = await axios.put(endpoint, {
        headers: {
          authorization: store.authToken
        },
        customer
      });
      store.commit("setAuthToken", response.token);
    } catch (err) {
      response = err;
    }
    return response;
  };

  this.deleteCustomer = async function(id) {
    let response = null;
    try {
      let store = useStore();
      let endpoint = `${this.base_url}/customers/${id}`;
      response = await axios.delete(endpoint, {
        headers: {
          authorization: store.authToken
        }
      });
      store.commit("setAuthToken", response.token);
    } catch (err) {
      response = err;
    }
    return response;
  };
}

export default API;
