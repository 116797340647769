<template>
  <div class=" flex-1 space-y-10 bg-black">
    <simple-centered></simple-centered>
    <simple-with-select-menu-dark></simple-with-select-menu-dark>
    <simple-with-select-menu></simple-with-select-menu>
    <branded-with-background-image></branded-with-background-image>
    <with-branded-image-and-overlapping-cards></with-branded-image-and-overlapping-cards>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import SimpleCentered from "./../../../components/tailwind-base/marketing/headers/SimpleCentered.vue";
import SimpleWithSelectMenuDark from "./../../../components/tailwind-base/marketing/headers/SimpleWithSelectMenuDark.vue";
import SimpleWithSelectMenu from "./../../../components/tailwind-base/marketing/headers/SimpleWithSelectMenu.vue";
import BrandedWithBackgroundImage from "./../../../components/tailwind-base/marketing/headers/BrandedWithBackgroundImage.vue";
import WithBrandedImageAndOverlappingCards from "./../../../components/tailwind-base/marketing/headers/WithBrandedImageAndOverlappingCards.vue";

export default {
  components: {
    SimpleCentered,
    SimpleWithSelectMenuDark,
    SimpleWithSelectMenu,
    BrandedWithBackgroundImage,
    WithBrandedImageAndOverlappingCards
  },
  setup() {
    const state = reactive({
      count: 0
    });

    return {
      ...toRefs(state)
    };
  }
};
</script>

<style lang="scss" scoped></style>
