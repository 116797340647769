<template>
  <div class="shadow rounded">
    <div class="flex p-2">
      <div class="flex-grow flex-1">
        <div class="font-semibold text-lg">Due Next</div>
        <div class="font-semibold text-lg">Billing Cycle</div>
        <!-- <div class='font-semibold text-md'>  </div>
        <div class='font-semibold text-md'> Cycle </div> -->
      </div>
      <div class="flex-grow text-right">
        <div class="flex-1">
          <div class="text-lg font-semibold">{{ titleText }}</div>
          <div class="text-lg font-regular">$ {{ amount }}</div>
          <!-- <button
            @click="buttonClicked"
            class="bg-indigo-600 rounded text-white p-1 m-1 text-md"
          >
            {{ buttonText }}
          </button> -->
          <!-- <a href="/apps/admin/invoices" class="text-indigo-600 m-1 p-1 w-full">
            View All
          </a> -->
        </div>
      </div>
    </div>
    <div class="bg-gray-100 w-full p-2">
      {{ dueDate }}
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";

export default {
  setup() {
    let store = useStore();
    let amount = store.state.fleetSummary.comingDue;
    const dueDate = computed(() => {
      const today = new Date();
      const lastDay = new Date(today.getFullYear(), today.getMonth() + 2, 0);
      return lastDay.toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
      });
    });
    return {
      amount,
      dueDate
    };
  },
  methods: {},
  props: ["titleText", "subText", "footerText", "buttonText"]
};
</script>

<style lang="scss" scoped></style>
