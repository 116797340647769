<template>
  <div class="flex h-screen">
    <!-- Red Side -->
    <div class="flex-1 flex items-center justify-center bg-red-700 rounded-l">
      <div class="text-white text-center">
        <h2 class="text-4xl font-bold mb-2">Welcome Back!</h2>
        <p class="text-lg">Please sign in to continue.</p>
      </div>
    </div>
    <!-- Login Panel Side -->
    <div class="flex-1 flex items-center justify-center bg-gray-100 rounded-r">
      <div class="w-full max-w-md">
        <form
          @submit.prevent="login"
          class="bg-white shadow-lg rounded-lg px-10 pt-8 pb-8 mb-4"
        >
          <div class="mb-6">
            <label
              class="block text-gray-700 text-sm font-semibold mb-2"
              for="username"
            >
              Username
            </label>
            <input
              v-model="username"
              class="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="username"
              type="text"
              placeholder="Username"
            />
          </div>
          <div class="mb-8">
            <label
              class="block text-gray-700 text-sm font-semibold mb-2"
              for="password"
            >
              Password
            </label>
            <input
              v-model="password"
              class="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
              id="password"
              type="password"
              placeholder="Password"
            />
          </div>
          <div class="flex items-center justify-between">
            <button
              class="bg-red-700 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition-colors duration-200"
              type="submit"
            >
              Sign In
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import axios from "axios";

export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const username = ref("");
    const password = ref("");

    const login = async () => {
      try {
        const response = await axios.post(
          "https://https:/development.api.myfleet.services/api/v0/authentication/mfc-login",
          {
            username: username.value,
            password: password.value
          }
        );
        let user = response.data;
        console.log("Response for User", user.sessionId);
        store.commit("setUsername", username.value);
        store.dispatch("storeLoginResponse", {
          username: username.value,
          sessionId: user.sessionId
        });

        router.push({ name: "Turn Down Dashboard" });
      } catch (error) {
        console.error("Login failed:", error);
      }
    };

    return { username, password, login };
  }
};
</script>
import { useRoute } from "vue-router";
