import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./tailwind.css";
import "./index.css";
import QrReader from 'vue3-qr-reader';

createApp(App)
  .use(store)
  .use(router)
  .use(QrReader)
  .mount("#app");
