<template>
  <!-- Navbar -->
  <Disclosure as="nav" class="bg-gray-50" v-slot="{ open }">
    <div class=" mx-auto px-4 sm:px-6 lg:px-8">
      <div
        class="relative h-16 flex items-center justify-between border-b border-gray-200"
      >
        <div class="flex items-center">
          <div class="flex-shrink-0">
            <img
              class="h-8 w-auto"
              src="https://services.soundbilling.com/wp-content/uploads/2023/04/jli-mfc-2-300x45.png"
              alt="Workflow"
            />
          </div>

          <!-- Links section -->
          <div class="hidden lg:block lg:ml-10">
            <div class="flex-1">
              <div>888-999-9474</div>
              <div>admin@myfleetcenter.com</div>
            </div>
          </div>
        </div>
        <div class="max-w-lg w-full lg:max-w-xs lg:pl-8">
          <label for="search" class="sr-only">Search</label>
          <div class="relative text-gray-400 focus-within:text-gray-500">
            <div
              class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center"
            >
              <SearchIcon class="h-5 w-5" aria-hidden="true" />
            </div>
            <input
              id="search"
              class="block w-full bg-white py-2 pl-10 pr-3 border border-gray-300 rounded-md leading-5 text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500 focus:placeholder-gray-500 sm:text-sm"
              placeholder="Invoice / Check Lookup"
              type="search"
              name="search"
            />
          </div>
        </div>
        <div
          class="hidden md:inline-flex flex-1 px-2 flex justify-center lg:ml-6 lg:justify-end"
        >
          <!-- Search section -->

          <div class="flex space-x-4">
            <a
              v-for="item in navigation"
              :key="item.name"
              :href="item.href"
              :class="[
                item.current ? 'bg-indigo-100' : 'hover:text-indigo-700',
                'px-3 py-2 rounded-md text-sm font-medium text-indigo-900 text-center'
              ]"
              :aria-current="item.current ? 'page' : undefined"
              >{{ item.name }}
              <!-- {{
                navigation.indexOf(item) === navigation.length - 1 ? "" : ` | `
              }} -->
            </a>
          </div>
        </div>
        <div class="flex lg:hidden">
          <!-- Mobile menu button -->
          <DisclosureButton
            class="bg-gray-50 p-2 inline-flex items-center justify-center rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-purple-500"
          >
            <span class="sr-only">Open main menu</span>
            <MenuIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
            <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
          </DisclosureButton>
        </div>

        <!-- Actions section -->
        <div class="hidden lg:block lg:ml-4">
          <div class="flex items-center">
            <button
              class="bg-gray-50 flex-shrink-0 rounded-full p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-purple-500"
            >
              <span class="sr-only">View notifications</span>
              <BellIcon class="h-6 w-6" aria-hidden="true" />
            </button>

            <!-- Profile dropdown -->
            <!-- <Menu as="div" class="ml-3 relative flex-shrink-0">
              <div>
                <MenuButton
                  class="bg-gray-50 rounded-full flex text-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-purple-500"
                >
                  <span class="sr-only">Open user menu</span>
                  <img
                    class="rounded-full h-8 w-8"
                    :src="user.imageUrl"
                    alt=""
                  />
                </MenuButton>
              </div>
              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="origin-top-right absolute z-10 right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <MenuItem
                    v-for="item in userNavigation"
                    :key="item.name"
                    v-slot="{ active }"
                  >
                    <a
                      :href="item.href"
                      :class="[
                        active ? 'bg-gray-100' : '',
                        'block py-2 px-4 text-sm text-gray-700'
                      ]"
                    >
                      {{ item.name }}
                    </a>
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu> -->
          </div>
        </div>
      </div>
    </div>

    <DisclosurePanel class="bg-gray-50 border-b border-gray-200 lg:hidden">
      <div class="px-2 pt-2 pb-3 space-y-1">
        <a
          v-for="item in navigation"
          :key="item.name"
          :href="item.href"
          :class="[
            item.current ? 'bg-gray-100' : 'hover:bg-gray-100',
            'block px-3 py-2 rounded-md font-medium text-gray-900'
          ]"
          :aria-current="item.current ? 'page' : undefined"
          >{{ item.name }}</a
        >
      </div>
      <!-- <div class="pt-4 pb-3 border-t border-gray-200">
        <div class="px-5 flex items-center">
          <div class="flex-shrink-0">
            <img class="rounded-full h-10 w-10" :src="user.imageUrl" alt="" />
          </div>
          <div class="ml-3">
            <div class="text-base font-medium text-gray-800">
              {{ user.name }}
            </div>
            <div class="text-sm font-medium text-gray-500">
              {{ user.email }}
            </div>
          </div>
          <button
            class="ml-auto bg-gray-50 flex-shrink-0 rounded-full p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-purple-500"
          >
            <span class="sr-only">View notifications</span>
            <BellIcon class="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div class="mt-3 px-2 space-y-1">
          <a
            v-for="item in userNavigation"
            :key="item.name"
            :href="item.href"
            class="block rounded-md py-2 px-3 text-base font-medium text-gray-900 hover:bg-gray-100"
            >{{ item.name }}</a
          >
        </div>
      </div> -->
    </DisclosurePanel>
  </Disclosure>
</template>

<script>
import { reactive, toRefs } from "vue";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel
  // Menu,
  // MenuButton,
  // MenuItem,
  // MenuItems
} from "@headlessui/vue";
import { BellIcon, MenuIcon, XIcon, SearchIcon } from "@heroicons/vue/outline";
import { computed } from 'vue';
import { useStore } from 'vuex';

// const navigation = [
//   { name: "Invoices", href: `https://www.myfleetcenter.com/myfleetcenter/shared/invoicelookup.aspx?sid=${}&lang=en-US`, current: false },
//   { name: "Documents", href: "#", current: false },
//   { name: "My Account", href: "#", current: false }
// ];
const userNavigation = [
  { name: "Your Profile", href: "#" },
  { name: "Settings", href: "#" },
  { name: "Sign out", href: "#" }
];
const user = {
  name: "Whitney Francis",
  email: "whitneyfrancis@example.com",
  imageUrl:
    "https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
};
export default {
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    // Menu,
    // MenuButton,
    // MenuItem,
    // MenuItems,
    BellIcon,
    MenuIcon,
    SearchIcon,
    XIcon
  },
  setup() {
    const store = useStore();

    const navigation = computed(() => [
      {
        name: "Invoices",
        href: `https://myfleetcenter.com/myfleetcenter/shared/invoicelookup.aspx?sid=${store.state.sessionId}&lang=en-US`,
        current: false
      },
      {
        name: "Documents",
        href: `https://myfleetcenter.com/myfleetcenter/fleets/documents.aspx?sid=${store.state.sessionId}&lang=en-US`,
        current: false
      },
      {
        name: "My Account",
        href: `https://myfleetcenter.com/myfleetcenter/shared/myaccount.aspx?sid=${store.state.sessionId}&lang=en-US`,
        current: false
      },
      {
        name: "Contact Us",
        href: `https://myfleetcenter.com/myfleetcenter/shared/contact.aspx?sid=${store.state.sessionId}&lang=en-US`,
        current: false
      },
      {
        name: "Find A Store",
        href: `https://myfleetcenter.com/myfleetcenter/fleets/findstore.aspx?sid=${store.state.sessionId}&lang=en-US`,
        current: false
      }
    ]);

    const state = reactive({
      count: 0
    });

    return {
      navigation,
      userNavigation,
      user,
      ...toRefs(state)
    };
  }
};
</script>

<style lang="scss" scoped></style>
