import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
//import TailwindTest from "../views/TailwindTest.vue";
import Dashboard from "../views/Dashboard.vue";
import LoginPage from "../views/LoginPage.vue";
import LoadingPage from "../views/Fleets/LoadingPage.vue";
import TurnDownDashboard from "../views/TurnDownDashboard.vue";
import TailwindHeros from "./../views/tailwind-pages/marketing/Hero.vue";
import TailwindFeatures from "./../views/tailwind-pages/marketing/Features.vue";
import TailwindCTAs from "./../views/tailwind-pages/marketing/CTAs.vue";
import TailwindPricing from "./../views/tailwind-pages/marketing/Pricing.vue";
import TailwindHeader from "./../views/tailwind-pages/marketing/Header.vue";
import TailwindFAQ from "./../views/tailwind-pages/marketing/FAQ.vue";
import AdminInvoices from "./../views/apps/admin/invoices/List.vue";
import AdminInvoiceDetails from "./../views/apps/admin/invoices/Details.vue";
import AdminCompanies from "./../views/apps/admin/companies/List.vue";
import AdminCompanyDetails from "./../views/apps/admin/companies/Details.vue";
import AdminDocuments from "./../views/apps/admin/documents/List.vue";
import AdminDocumentDetails from "./../views/apps/admin/documents/Details.vue";
import AdminFleets from "./../views/apps/admin/fleets/List.vue";
import AdminFleetDetails from "./../views/apps/admin/fleets/Details.vue";
import AdminPayments from "./../views/apps/admin/payments/List.vue";
import AdminPaymentDetails from "./../views/apps/admin/payments/Details.vue";
import AdminReports from "./../views/apps/admin/reports/List.vue";
import AdminReportDetails from "./../views/apps/admin/reports/Details.vue";
import AdminServiceCenters from "./../views/apps/admin/service-centers/List.vue";
import AdminServiceCenterDetails from "./../views/apps/admin/service-centers/Details.vue";
import AdminSearch from "./../views/apps/admin/search/Search.vue";
import FleetsDashboard from "./../views/apps/fleets/Dashboard.vue";
const routes = [
  { path: "/", name: "Dashboard", component: Dashboard },
  { path: "/login", name: "Login", component: LoginPage },
  {
    path: "/turn-down",
    name: "Turn Down Dashboard",
    component: TurnDownDashboard
  },
  { path: "/home", name: "Home", component: Home, meta: { title: "Home" } },
  {
    path: "/loading",
    name: "Loading",
    component: LoadingPage
  },
  {
    path: "/tailwind/marketing/hero",
    name: "Heros",
    component: TailwindHeros,
    meta: { title: "Heros" }
  },
  {
    path: "/tailwind/marketing/features",
    name: "Features",
    component: TailwindFeatures,
    meta: { title: "Features" }
  },
  {
    path: "/tailwind/marketing/ctas",
    name: "CTAs",
    component: TailwindCTAs,
    meta: { title: "CTAs" }
  },
  {
    path: "/tailwind/marketing/pricing",
    name: "Pricing",
    component: TailwindPricing,
    meta: { title: "Pricing" }
  },
  {
    path: "/tailwind/marketing/header",
    name: "Header",
    component: TailwindHeader,
    meta: { title: "Header" }
  },
  {
    path: "/tailwind/marketing/faq",
    name: "FAQ",
    component: TailwindFAQ,
    meta: { title: "FAQ" }
  },
  {
    path: "/apps/admin/invoices",
    name: "Admin Invoices",
    component: AdminInvoices,
    meta: { title: "Admin Invoices" }
  },
  {
    path: "/apps/admin/invoices/:id",
    name: "Admin Invoice Details",
    component: AdminInvoiceDetails,
    meta: { title: "Admin Invoice Details" }
  },
  {
    path: "/apps/admin/companies",
    name: "Admin Companies List",
    component: AdminCompanies,
    meta: { title: "Admin Companies List" }
  },
  {
    path: "/apps/admin/companies/:id",
    name: "Admin Company Details",
    component: AdminCompanyDetails,
    meta: { title: "Admin Company Details" }
  },
  {
    path: "/apps/admin/documents",
    name: "Admin Documents List",
    component: AdminDocuments,
    meta: { title: "Admin Documents List" }
  },
  {
    path: "/apps/admin/documents/:id",
    name: "Admin Documents Details",
    component: AdminDocumentDetails,
    meta: { title: "Admin Documents Details" }
  },
  {
    path: "/apps/admin/fleets",
    name: "Admin Fleets List",
    component: AdminFleets,
    meta: { title: "Admin Fleets List" }
  },
  {
    path: "/apps/admin/fleets/:id",
    name: "Admin Fleet Details",
    component: AdminFleetDetails,
    meta: { title: "Admin Fleet Details" }
  },
  {
    path: "/apps/admin/payments",
    name: "Admin Payment List",
    component: AdminPayments,
    meta: { title: "Admin Payment List" }
  },
  {
    path: "/apps/admin/payments/:id",
    name: "Admin Payment Details",
    component: AdminPaymentDetails,
    meta: { title: "Admin Payment Details" }
  },
  {
    path: "/apps/admin/reports",
    name: "Admin Report List",
    component: AdminReports,
    meta: { title: "Admin Report List" }
  },
  {
    path: "/apps/admin/reports/:id",
    name: "Admin Report Details",
    component: AdminReportDetails,
    meta: { title: "Admin Report Details" }
  },
  {
    path: "/apps/admin/service-centers",
    name: "Admin Service Center List",
    component: AdminServiceCenters,
    meta: { title: "Admin Service Center List" }
  },
  {
    path: "/apps/admin/service-centers/:id",
    name: "Admin Service Center Details",
    component: AdminServiceCenterDetails,
    meta: { title: "Admin Service Center Details" }
  },
  {
    path: "/apps/admin/search",
    name: "Admin Search",
    component: AdminSearch,
    meta: { title: "Admin Search" }
  },
  {
    path: "/apps/fleets/dashboard",
    name: "Fleets Dashboard",
    component: FleetsDashboard,
    meta: { title: "Fleets Dashboard" }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
