<template>
  <div class="shadow rounded">
    <div class="flex p-2">
      <div class="flex text-left">
        <div class="flex-1">
          <div class="text-lg font-semibold break-word">{{ titleText }}</div>
          <div class="text-lg font-semibold break-word">{{ subText }}</div>
        </div>
      </div>
      <div class="w-full">
        <div class="flex w-full">
          <div class="m-2 w-full text-right">$ {{ pastDue }}</div>
          <div class="">
            <button
            @click="buttonClicked(`https://myfleetcenter.com/myfleetcenter/shared/invoicelookup.aspx?sid=${sessionId}&lang=en-US`)"
              class="jiffy-lube-button rounded text-white p-1 m-1 text-md w-32"
            >
              Pay Past Due
            </button>
          </div>
        </div>
        <div class="flex w-full text-right">
          <div class="m-2 w-full">$ {{ totalDue }}</div>
          <div class="">
            <button
              @click="buttonClicked(`https://myfleetcenter.com/myfleetcenter/shared/invoicelookup.aspx?sid=${sessionId}&lang=en-US`)"
              class="jiffy-lube-button rounded text-white p-1 m-1 text-md w-32"
            >
              Pay Total
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-gray-100 w-full p-2">
      To apply credit click the box on the next page
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
export default {
  setup() {
    const store = useStore();

    const pastDue = computed(() => store.state.fleetSummary.pastDue);
    const totalDue = computed(() => store.state.fleetSummary.balance);
    const sessionId = computed(() => store.state.sessionId);
    console.log(pastDue.value, totalDue.value);
    return {
      pastDue,
      totalDue,
      sessionId
    };
  },
  methods: {
    buttonClicked(url) {
      window.open(url);
    }
  },
  props: ["clickFunction", "titleText", "subText"]
};
</script>

<style lang="scss" scoped></style>
