<template>
  <nav class="flex" aria-label="Breadcrumb">
    <ol class="flex items-center space-x-4" role="list">
      <li>
        <div>
          <a
            href="#"
            class="text-sm font-medium text-gray-500 hover:text-gray-700"
            >Fleets</a
          >
        </div>
      </li>
      <li>
        <div class="flex items-center">
          <ChevronRightIcon
            class="flex-shrink-0 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          <a
            href="#"
            class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
            >Dashboard</a
          >
        </div>
      </li>
    </ol>
  </nav>
</template>

<script>
import { reactive, toRefs } from "vue";
import { ChevronRightIcon } from "@heroicons/vue/solid";

export default {
  components: { ChevronRightIcon },
  setup() {
    const state = reactive({
      count: 0
    });

    return {
      ...toRefs(state)
    };
  }
};
</script>

<style lang="scss" scoped></style>
