<!--
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ]
  }
  ```
-->
<template>
  <div class="relative bg-white ">
    <navbar></navbar>
    <!-- Page heading -->
    <header class="bg-gray-50 py-8">
      <div
        class=" mx-auto px-4 sm:px-6 lg:px-8 xl:flex xl:items-center xl:justify-between"
      >
        <div class="flex-1 min-w-0">
          <breadcrumb></breadcrumb>
        </div>
      </div>
    </header>

    <main class="pt-8 pb-16">
      <div class=" mx-auto sm:px-6 lg:px-8">
        <div class="px-4 sm:px-0">
          <div class="grid md:grid-cols-9 gap-4">
            <div class="md:col-span-3 col-span-9 shadow flex flex-col">
              <div class="shadow"><turned-down></turned-down></div>
              <div class="py-3"></div>
              <div class="flex-grow overflow-y-auto shadow">
                <invoice-list></invoice-list>
              </div>
            </div>
            <div class="md:col-span-3 col-span-9 shadow flex flex-col h-screen">
              <pay-now v-on-pay-widget-button-clicked="respondToPay"></pay-now>
            </div>
            <div
              class="md:col-span-3 col-span-9 shadow flex flex-col space-y-4"
            >
              <pay-widget
                pastDue="$99.00"
                v-on:pay-widget-button-clicked="respondToClick"
                titleText="Amount"
                subText="Due"
                totalDue="$149.00"
                footerText="To apply your credit"
              ></pay-widget>
              <next-billing-cycle
                footerText="4/31/2024"
                titleText=""
                buttonText="View"
                :subText="comingDueAsANumber"
              ></next-billing-cycle>
              <!-- <pay-widget
                  buttonText="PAY"
                  v-on:pay-widget-button-clicked="respondToClick"
                  titleText="Total Balance"
                  :subText="totalBalanceAsANumber"
                  footerText="View Details"
                ></pay-widget> -->
              <!-- <view-invoices-widget
                buttonText="View"
                v-on:pay-widget-button-clicked="respondToClick"
                titleText="Invoices"
                footerText="To view and pay specific invoices click view above"
              ></view-invoices-widget> -->
              <!-- <recent-statement-widget
                v-on:pay-widget-button-clicked="respondToClick"
                titleText="MOST RECENT STATEMENT"
                footerText="See all of the details"
              ></recent-statement-widget> -->
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore, mapState } from "vuex";
import Navbar from "../components/common/Navbar.vue";
import Breadcrumb from "../components/common/Breadcrumb.vue";
// import List from "../components/invoices/InvoiceList.vue";
// import ServicesDue from "../components/turned-down/ServicesDue.vue";
import TurnedDown from "../components/turned-down/TurnedDown.vue";
import PayNow from "../components/dashboard/PayNow.vue";
import PayWidget from "../components/dashboard/PayWidgetTurnDown.vue";
//import ViewInvoicesWidget from "../components/dashboard/ViewInvoicesWidget.vue";
import NextBillingCycle from "../components/dashboard/NextBillingCycle.vue";
//import RecentStatementWidget from "../components/dashboard/RecentStatementWidget.vue";
import InvoiceList from "../components/invoices/InvoiceList.vue";
import axios from "axios";

export default {
  components: {
    Navbar,
    Breadcrumb,
    // List,
    PayNow,
    PayWidget,
    NextBillingCycle,
    //ViewInvoicesWidget,
    // ServicesDue,
    TurnedDown,
    //RecentStatementWidget,
    InvoiceList
  },
  async setup() {
    const open = ref(false);
    let store = useStore();
    const error = ref(null);
    const loadingMessage = ref("");
    const loading = ref(false);
    const route = useRoute();
    const sessionId = route.query.sessionId;
    if (sessionId) {
      store.commit("setSessionId", sessionId);
    }

    const fetchFleetSummary = async () => {
      console.log("fetching fleet summary: ")
      try {
        const response = await axios.get(
          `https://development.api.myfleet.services/api/v0/dashboard/fleets/${store.state.sessionId}/summary`
        );
        console.log(response.data);
        store.commit("setFleetSummary", response.data);
        loadingMessage.value = "Loading Turndown Services...";
      } catch (err) {
        error.value = "Failed to load Fleet Summary";
        throw err; // Rethrow the error to stop further execution
      }
    };
    const loadData = async () => {
      try {
        await fetchFleetSummary();
      } finally {
        loading.value = false;
      }
    };
    onMounted(loadData);
    return {
      open
    };
  },
  methods: {
    respondToClick() {
      console.log("responding");
    },
    respondToPay() {
      console.log("responding to widget click");
    }
  },
  computed: {
    pastDueTotalAsNumber() {
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD"
      });
      return formatter.format(this.pastDueTotal);
    },
    comingDueAsANumber() {
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD"
      });
      //TODO: REVERT THIS:
      //return formatter.format(this.totalDue - this.pastDueTotal);
      return formatter.format(67.0);
    },
    totalBalanceAsANumber() {
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD"
      });
      return formatter.format(this.totalDue);
    },
    ...mapState(["totalDue", "pastDueTotal", "userId"])
  }
};
</script>
