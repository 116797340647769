<template>
  <div class="flex justify-center items-center h-screen">
    <div v-if="loading" class="text-center">
      <div
        class="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
        role="status"
      ></div>
      <p>{{ loadingMessage }}</p>
    </div>
    <div v-else-if="error" class="text-center text-red-500">
      <p>Error: {{ error }}</p>
    </div>
    <div v-else>
      <TurndownServices />
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import axios from "axios";
import TurndownServices from "./../../components/turned-down/TurnedDownTest.vue";

export default {
  setup() {
    const store = useStore();
    const loading = ref(true);
    const loadingMessage = ref("Loading...");
    const error = ref(null);

    const fetchFleetSummary = async () => {
      try {
        const response = await axios.get(
          `https://development.api.myfleet.services/api/v0/dashboard/fleets/${store.state.sessionId}/summary`
        );
        store.commit("setFleetSummary", response.data);
        loadingMessage.value = "Loading Turndown Services...";
      } catch (err) {
        error.value = "Failed to load Fleet Summary";
        throw err; // Rethrow the error to stop further execution
      }
    };

    const fetchTurndownServices = async () => {
      try {
        const response = await axios.post(
          `https://development.api.myfleet.services/api/v0/dashboard/fleets/${store.state.sessionId}/turndown-services`,
          {
            start: 1,
            length: 10,
            columns: [{ data: "nextServiceDate", name: "nextServiceDate" }],
            order: [{ dir: "asc" }],
            draw: 0,
            showPastDueServiceIntervals: true,
            dismissedValue: -1
          }
        );
        store.commit("setTurndownServices", response.data);
      } catch (err) {
        error.value = "Failed to load Turndown Services";
        throw err; // Rethrow the error to stop further execution
      }
    };

    const loadData = async () => {
      try {
        await fetchFleetSummary();
        await fetchTurndownServices();
      } finally {
        loading.value = false;
      }
    };

    onMounted(loadData);

    return { loading, loadingMessage, error, TurndownServices };
  }
};
</script>

<style>
.spinner-border {
  border-color: #3490dc;
  border-top-color: transparent;
}
</style>
