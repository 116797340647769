<!--
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ]
  }
  ```
-->
<template>
  <div class="relative min-h-screen bg-white ">
    <navbar></navbar>
    <!-- Page heading -->
    <header class="bg-gray-50 py-8">
      <div
        class=" mx-auto px-4 sm:px-6 lg:px-8 xl:flex xl:items-center xl:justify-between"
      >
        <div class="flex-1 min-w-0">
          <breadcrumb></breadcrumb>
        </div>
      </div>
    </header>

    <main class="pt-8 pb-16">
      <div class=" mx-auto sm:px-6 lg:px-8">
        <div class="px-4 sm:px-0">
          <div class="grid grid-cols-9  h-full">
            <div class="col-span-2 shadow">
              <list></list>
            </div>
            <div class="col-span-5">
              <pay-now v-on-pay-widget-button-clicked="respondToPay"></pay-now>
            </div>
            <div class="col-span-2 space-y-4">
              <pay-widget
                buttonText="PAY"
                v-on:pay-widget-button-clicked="respondToClick"
                titleText="Past Due"
                :subText="pastDueTotalAsNumber"
                footerText="View Details"
              ></pay-widget>
              <next-billing-cycle
                footerText="View Details"
                titleText="Coming Due"
                :subText="comingDueAsANumber"
              ></next-billing-cycle>
              <pay-widget
                buttonText="PAY"
                v-on:pay-widget-button-clicked="respondToClick"
                titleText="Total Balance"
                :subText="totalBalanceAsANumber"
                footerText="View Details"
              ></pay-widget>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore, mapState } from "vuex";
import Navbar from "../components/common/Navbar.vue";
import Breadcrumb from "../components/common/Breadcrumb.vue";
import List from "../components/invoices/InvoiceList.vue";
import PayNow from "../components/dashboard/PayNow.vue";
import PayWidget from "../components/dashboard/PayWidget.vue";
import NextBillingCycle from "../components/dashboard/NextBillingCycle.vue";

export default {
  components: {
    Navbar,
    Breadcrumb,
    List,
    PayNow,
    PayWidget,
    NextBillingCycle
  },
  async setup() {
    const open = ref(false);
    let store = useStore();
    store.commit("setTotalDue", 729365.78);
    store.commit("setPastDueTotal", 281459.35);
    return {
      open
    };
  },
  methods: {
    respondToClick() {
      console.log("responding");
    },
    respondToPay() {
      console.log("responding to widget click");
    }
  },
  computed:{
    pastDueTotalAsNumber(){
      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return formatter.format(this.pastDueTotal);
    },
    comingDueAsANumber(){
      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return formatter.format(this.totalDue - this.pastDueTotal);
    },
    totalBalanceAsANumber(){
      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return formatter.format(this.totalDue);
    },
    ...mapState(['totalDue', 'pastDueTotal'])
  }
};
</script>
