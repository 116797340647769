<template>
  <div class="fleet-dashboard font-montserrat">
    <div
      class="logged-in-menu flex flex-start items-stretch  h-18 items-center bg-white"
    >
      <div
        class="logo-banner flex-grow bg-blue-500 text-white h-12 pl-8 pt-2 items-center"
      >
        <span class="tracking-widest text-xl"> Logo </span>
      </div>
      <div class="search-banner flex-grow font-28 text-center pt-3">
        <span class="mx-auto ">SEARCH</span>
      </div>
      <div class="searchbox-banner flex-grow">
        <div class="pt-3 relative rounded-md shadow-sm">
          <span
            class="absolute inset-y-0 left-0 pl-3 flex items-center text-blue-600"
          >
            <i class="fas fa-search pt-3"></i>
          </span>
          <input
            type="text"
            class="border-solid border-2 border-blue-600 rounded placeholder-blue-500 h-100 w-full"
            placeholder="          INVOICE/CHECK LOOKUP"
          />
        </div>
      </div>
      <div class="contact-banner flex-grow pt-3 text-center">
        (888) 999-9497
      </div>
      <div class="user-holder flex flex-start">
        <div
          class="bg-blue-500 rounded-full mx-auto mt-3"
          style="width:30px; height:30px"
        ></div>
        <div class="mt-3">
          <span class="mt-2 text-sm pl-2">Username </span>
        </div>
      </div>
      <div class="menu-holder flex-grow text-center">
        <i class="fas fa-bars fa-2x pt-3"></i>
      </div>
    </div>
    <div class="fleet-dashboard-main bg-gray-200 pt-8 pb-8 flex flex-row">
      <div
        class="w-3/4 flex shadow-3xl ml-8"
        style="min-height:400px; background-color:#51DAB5"
      >
        <div class="w-1/3 text-blue-500 text-2xl pl-6 my-auto">
          <span class="mt-24"> YOU HAVE A <br /> </span>

          <span class="text-white text-5xl font-semibold">
            ${{ credit_available }}
          </span>
          <br />
          CREDIT, WOULD YOU LIKE TO USE IT?
        </div>
        <div class="w-1/3 h-full">
          <div class="w-full text-center text-white font-semibold text-6xl">
            ${{ total_due }}
          </div>
          <div class="w-full text-center text-blue-500 text-2xl font-thin pb-6">
            CURRENT BALANCE
          </div>
          <div class="w-full text-center pb-12 mb-6">
            <button
              class="bg-blue-500 text-white text-5xl shadow-halo rounded-full"
              style="height:250px; width:250px"
            >
              PAY NOW
            </button>
          </div>
        </div>
        <div class="w-1/3"></div>
      </div>
      <div class="w-1/4 flex flex-col">
        <div class="bg-white h-full ml-2 mr-1 rounded shadow">
          <div class="flex flex col">
            <div class="flex w-full pt-4">
              <div class="w-1/2 pl-4 pt-1 text-xl font-thin">PAST DUE</div>
              <div
                class="w-1/2 text-2xl font-semibold text-red-500 text-right pr-2"
              >
                ${{ overdue_total_due }}
              </div>
            </div>
          </div>
          <div class="flex flex col">
            <div class="flex w-full pt-4 items-baseline">
              <div class="w-1/2 pl-4 text-baseline">
                <span class="text-sm pt-12">
                  VIEW DETAILS
                </span>
              </div>
              <div class="w-1/2 pb-2 text-right pr-1">
                <button
                  class="bg-red-500 rounded text-xl text-white w-4/5"
                  style="min-height:45px"
                >
                  PAY
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-white h-full ml-2 mr-1 mt-2 rounded shadow">
          <div class="flex flex col">
            <div class="flex w-full pt-4">
              <div class="w-1/2 pl-4 pt-1 text-xl font-thin">COMING DUE</div>
              <div
                class="w-1/2 text-2xl font-semibold text-blue-500 text-right pr-2"
              >
                $94.89
              </div>
            </div>
          </div>
          <div class="flex flex col">
            <div class="flex w-full pt-8 items-baseline">
              <div class="w-1/2 pl-4 "></div>
              <div class="w-1/2 text-right pr-1 text-baseline">
                <span class="text-sm mt-8 pr-1 font-thin">
                  NEXT 30 DAYS
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-blue-500 h-full ml-2 mr-1 mt-2 rounded shadow">
          <div class="flex flex-row text-white text-xl font-thin pt-3 pl-4">
            EARLY PAY ELIGIBLE
          </div>
          <div class="flex flex-row pt-10">
            <div class="h-full w-1/2  font-thin pl-6 ">
              <span class="text-white text-2xl font-semibold"> 15 </span>
              INVOICES <br />
              SEE DETAILS
            </div>
            <div class="h-full w-1/2 text-right">
              <button
                class="bg-white rounded text-xl mr-1 mb-1 text-blue-500 w-4/5"
                style="min-height:45px"
              >
                PAY
              </button>
            </div>
          </div>
        </div>
        <div
          class="h-full mt-6 pt-1 ml-2 mr-1 rounded shadow flex"
          style="background-color:#EAF1F2"
        >
          <div class="w-3/5 pl-4 text-blue-700 pt-4 text-xl font-thin">
            MOST <br />
            RECENT <br />
            STATEMENT
          </div>
          <div class="w-2/5 flex-col">
            <button
              class="bg-blue-700 rounded text-xl mb-1 mr-1 text-white w-full font-thin"
              style="min-height:45px"
            >
              PDF
            </button>
            <button
              class="bg-white rounded text-xl mb-1 mr-1 text-blue-500 w-full font-thin"
              style="min-height:45px"
            >
              EXCEL
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import { useStore } from "vuex";
import { mapState } from "vuex";
export default {
  setup() {
    let logo = ref("/jiffy-lube.png");
    let store = useStore();
    console.log(store.state.fleet_id);
    return { logo };
  },
  computed: {
    localComputed: {
      titleClasses() {
        if (this.overdue_total_due > 0.0) {
          return "text-red-500";
        }
        return "text-white";
      }
    },
    ...mapState([
      "fleetId",
      "authToken",
      "earlyPayTotalDue",
      "earlyPayInvoiceCount",
      "totalDue",
      "invoice_count",
      "dueDate",
      "creditAvailable",
      "overdueTotalDue",
      "overdueInvoiceCount",
      "error"
    ])
  },
  methods: {}
};
</script>
<style scoped>
::placeholder {
  font-size: 0.75em;
}
</style>
