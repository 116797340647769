<template>
  <div class="bg-black flex-1 space-y-10">
    <alt-side-by-side-with-images></alt-side-by-side-with-images>
    <Centered2by2Grid></Centered2by2Grid>
    <with-feature-grid-list></with-feature-grid-list>
    <with-feature-list></with-feature-list>
    <offset-two-by-two-grid></offset-two-by-two-grid>
    <simple-three-column></simple-three-column>
    <alternating-with-testimonial></alternating-with-testimonial>
    <four-by-two-grid-on-brand></four-by-two-grid-on-brand>
    <with-large-screen-shot> </with-large-screen-shot>
    <grid-with-offset-icons></grid-with-offset-icons>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import AltSideBySideWithImages from "./../../../components/tailwind-base/marketing/feature/AltSideBySideWithImages.vue";
import Centered2by2Grid from "./../../../components/tailwind-base/marketing/feature/Centered2by2Grid.vue";
import WithFeatureGridList from "./../../../components/tailwind-base/marketing/feature/WithFeatureGridList.vue";
import WithFeatureList from "./../../../components/tailwind-base/marketing/feature/WithFeatureList.vue";
import OffsetTwoByTwoGrid from "./../../../components/tailwind-base/marketing/feature/OffsetTwoByTwoGrid.vue";
import SimpleThreeColumn from "./../../../components/tailwind-base/marketing/feature/SimpleThreeColumn.vue";
import AlternatingWithTestimonial from "./../../../components/tailwind-base/marketing/feature/AlternatingWithTestimonial.vue";
import FourByTwoGridOnBrand from "./../../../components/tailwind-base/marketing/feature/FourByTwoGridOnBrand.vue";
import WithLargeScreenShot from "./../../../components/tailwind-base/marketing/feature/WithLargeScreenShot.vue";
import GridWithOffsetIcons from "./../../../components/tailwind-base/marketing/feature/GridWithOffsetIcons.vue";

export default {
  components: {
    AltSideBySideWithImages,
    Centered2by2Grid,
    WithFeatureGridList,
    WithFeatureList,
    OffsetTwoByTwoGrid,
    SimpleThreeColumn,
    AlternatingWithTestimonial,
    FourByTwoGridOnBrand,
    WithLargeScreenShot,
    GridWithOffsetIcons
  },
  setup() {
    const state = reactive({
      count: 0
    });

    return {
      ...toRefs(state)
    };
  }
};
</script>

<style lang="scss" scoped></style>
