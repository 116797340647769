<template>
  <div class="flex-1 shadow rounded">
    <div class="flex p-2">
      <div class="flex-grow">
        <button
          @click="buttonClicked"
          class="bg-indigo-600 rounded text-white p-2 m-2 text-md"
        >
          {{ buttonText }}
        </button>
      </div>
      <div class="flex-grow text-right">
        <div class="flex-1">
          <div class="text-lg font-semibold">{{ titleText }}</div>
          <div class="text-lg font-regular">{{ subText }}</div>
        </div>
      </div>
    </div>
    <div class="bg-gray-100 w-full p-2">
      {{ footerText }}
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
export default {
  setup() {
    const state = reactive({
      count: 0
    });

    return {
      ...toRefs(state)
    };
  },
  methods: {
    buttonClicked() {
      if (this.clickFunction) {
        this.clickFunction();
      }
      this.$emit("pay-widget-button-clicked");
    }
  },
  props: ["buttonText", "clickFunction", "titleText", "subText", "footerText"]
};
</script>

<style lang="scss" scoped></style>
