<template>
  <div class="w-full pr-4">
    <div class="mt-1 flex rounded-md shadow-sm w-full">
      <span
        class="h-10 inline-flex items-center px-3 mx-2 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm"
      >
        Search Term: {{isLoading}}
      </span>
      <input
        type="text"
        name="elasticSearchBox"
        id="elastic-search-box"
        class="pl-6 pt-3 pr-2 focus:ring-indigo-500 focus:border-indigo-500 flex-1  block w-full h-full rounded-none rounded-r-md sm:text-sm border-gray-300"
        placeholder="invoice 13456"
        @change="termChanged"
      />
      <button classs="" @click="search(store)">
        <i class="fa fa-search"></i> Search
      </button>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import { useStore } from "vuex";
import API from "./../../api/v1/admin.js";
export default {
  setup() {
    let store = useStore();
    console.log(store.state.loading);
    const state = reactive({
      searchTerms: ""
    });
    let search = async store => {
      let response = await new API().fetchSearchResults(store);
      console.log(response);
    };
    return {
      search,
      store,
      ...toRefs(state)
    };
  },
  methods: {
    termChanged(event) {
      this.searchTerms = event.target.value;
      console.log(this.searchTerms);
    }
  },
  computed: {
    isLoading() {
      return this.$store.state.isLoading;
    }
  },
  components:{},
};
</script>

<style scoped></style>
