<template>
  <div class="bg-black flex-1 space-y-10">
    <four-tiers-with-toggle></four-tiers-with-toggle>
    <single-price-with-details></single-price-with-details>
    <single-price-with-features-list></single-price-with-features-list>
    <split-with-brand-panel></split-with-brand-panel>
    <three-tier-with-emphasis></three-tier-with-emphasis>
    <two-tier-with-extra></two-tier-with-extra>
    <with-comparison-table></with-comparison-table>
    <three-tiers></three-tiers>
    <three-tiers-on-brand-and-feature-comparison></three-tiers-on-brand-and-feature-comparison>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import FourTiersWithToggle from "./../../../components/tailwind-base/marketing/pricing/FourTiersWithToggle.vue";
import SinglePriceWithDetails from "./../../../components/tailwind-base/marketing/pricing/SinglePriceWithDetails.vue";
import SinglePriceWithFeaturesList from "./../../../components/tailwind-base/marketing/pricing/SinglePriceWithFeaturesList.vue";
import SplitWithBrandPanel from "./../../../components/tailwind-base/marketing/pricing/SplitWithBrandPanel.vue";
import ThreeTierWithEmphasis from "./../../../components/tailwind-base/marketing/pricing/ThreeTierWithEmphasis.vue";
import TwoTierWithExtra from "./../../../components/tailwind-base/marketing/pricing/TwoTierWithExtra.vue";
import WithComparisonTable from "./../../../components/tailwind-base/marketing/pricing/WithComparisonTable.vue";
import ThreeTiers from "./../../../components/tailwind-base/marketing/pricing/ThreeTiers.vue";
import ThreeTiersOnBrandAndFeatureComparison from "./../../../components/tailwind-base/marketing/pricing/ThreeTiersOnBrandAndFeatureComparison.vue";

export default {
  components: {
    FourTiersWithToggle,
    SinglePriceWithDetails,
    SinglePriceWithFeaturesList,
    SplitWithBrandPanel,
    ThreeTierWithEmphasis,
    TwoTierWithExtra,
    WithComparisonTable,
    ThreeTiers,
    ThreeTiersOnBrandAndFeatureComparison
  },
  setup() {
    const state = reactive({
      count: 0
    });

    return {
      ...toRefs(state)
    };
  }
};
</script>

<style lang="scss" scoped></style>
