<template>
  <div>
    <div class="flex w-full">
      <div class="w-2/3">xxxx</div>
      <div class="w-1/3">
        yyyy
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import { useStore } from "vuex";
//import {useRoute} from "vue-router";
export default {
  async setup() {
    let store = useStore();
    if (store.state.selectedInvoice) {
      console.log("Invoice In State");
    } else {
      console.log("No Invoice");
    }
    const state = reactive({
      count: 0
    });

    return {
      ...toRefs(state)
    };
  }
};
</script>

<style scoped></style>
